.clientJetDetails {
  color: white;
  background-color: #0e1810;
  min-height: 100vh;
}
@media (max-width: 576px) {
  .clientJetDetails {
    min-height: 50vh;
  }
}
.clientJetDetails .cjbanner {
  z-index: 2;
  width: 100%;
  height: 75vh;
  overflow-y: hidden;
}
@media (max-width: 1025px) {
  .clientJetDetails .cjbanner {
    margin-top: 0;
    margin-right: 0px;
  }
}
@media (max-width: 768px) {
  .clientJetDetails .cjbanner {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .clientJetDetails .cjbanner {
    height: 50vh;
  }
}
@media (max-width: 440px) {
  .clientJetDetails .cjbanner {
    width: 100%;
  }
}
.clientJetDetails .cjbanner .slider1 img {
  height: 75vh;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 576px) {
  .clientJetDetails .cjbanner .slider1 img {
    height: 50vh;
  }
}
.clientJetDetails .cjbanner .slider1 .slick-dots {
  bottom: 75px;
}
.clientJetDetails .cjbanner .slider1 .slick-dots li {
  width: 45px;
  height: 3px;
}
.clientJetDetails .cjbanner .slider1 .slick-prev {
  left: 70px;
  width: 50px;
  height: 50px;
  z-index: 1 !important;
}
.clientJetDetails .cjbanner .slider1 .slick-next {
  right: 70px;
  width: 50px;
  height: 50px;
  z-index: 1 !important;
}
@media (max-width: 576px) {
  .clientJetDetails .cjbanner .slider1 .slick-next {
    right: 25px;
  }
  .clientJetDetails .cjbanner .slider1 .slick-prev {
    left: 25px;
    width: 50px;
    height: 50px;
    z-index: 1 !important;
  }
  .clientJetDetails .cjbanner .slider1 .slick-dots {
    bottom: 25px;
  }
}
.clientJetDetails .cjbanner .slider1 .slick-dots .slick-active {
  background-color: #7df08e;
}
.clientJetDetails .cjbanner .slider1 .slick-dots li button {
  background-color: white;
  height: 3px;
  width: 40px;
  padding: 0px;
}
.clientJetDetails .cjbanner .slider1 .slick-dots li button:before {
  content: "";
  height: 2px !important;
  width: 40px !important;
}
.clientJetDetails .cjbanner .slider1 .slick-dots li.slick-active button {
  height: 2px !important;
}
.clientJetDetails .cjbanner .slider1 .slick-dots li.slick-active button:before {
  background-color: #7df08e;
  height: 3px !important;
  width: 80px;
}
.clientJetDetails .cjbanner .slider1 .slick-prev:before {
  content: url("../../assets//images/testonomial/prev.svg");
}
.clientJetDetails .cjbanner .slider1 .slick-next:before {
  content: url("../../assets//images/testonomial/next.svg");
}
@media (max-width: 576px) {
  .clientJetDetails .cjbanner .slider1 .slick-prev {
    top: 58%;
  }
  .clientJetDetails .cjbanner .slider1 .slick-next {
    top: 58%;
  }
  .clientJetDetails .cjbanner .slider1 .slick-dots li {
    width: auto;
  }
  .clientJetDetails .cjbanner .slider1 .slick-dots li button {
    background-color: white;
    width: 20px;
    padding: 0px;
  }
  .clientJetDetails .cjbanner .slider1 .slick-dots .slick-active {
    width: auto;
    background-color: #7df08e;
  }
  .clientJetDetails .cjbanner .slider1 .slick-dots li button:before {
    content: "";
    height: 2px !important;
    width: 20px !important;
  }
}
.clientJetDetails .detailsjet {
  padding: 60px 120px;
}
@media (max-width: 992px) {
  .clientJetDetails .detailsjet {
    padding: 60px 60px;
  }
}
@media (max-width: 768px) {
  .clientJetDetails .detailsjet {
    padding: 60px 30px;
  }
}
.clientJetDetails .detailsjet .heads {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .clientJetDetails .detailsjet .heads {
    display: block;
  }
}
.clientJetDetails .detailsjet .heads .line {
  height: 1px;
  background-color: #606060;
  width: 100%;
  margin: 0px 20px;
}
.clientJetDetails .detailsjet .heads p {
  white-space: nowrap;
  color: #606060;
  font-family: Ubuntu;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
.clientJetDetails .detailsjet .heads h2 {
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
}
@media (max-width: 576px) {
  .clientJetDetails .detailsjet .heads h2 {
    font-size: 24px;
  }
}
.clientJetDetails .detailsjet .pdf h4 {
  font-size: 30px;
}
@media (max-width: 992px) {
  .clientJetDetails .detailsjet .pdf h4 {
    font-size: 20px;
  }
}
.clientJetDetails .detailsjet .pdf .pdformate {
  background-color: #7df08e;
  margin-top: 20px;
  padding: 7px 20xp;
  width: -moz-fit-content;
  width: fit-content;
  color: black;
}
.clientJetDetails .detailsjet .hightight {
  padding: 60px 0px;
}
.clientJetDetails .detailsjet .hightight .inficon {
  background-color: #0e1810;
}
.clientJetDetails .detailsjet .hightight .inficon .infocards {
  border: 2px solid white;
  margin: 20px 20px;
  width: 122px;
  height: 121px;
  overflow: hidden;
  border-radius: 50%;
}
.clientJetDetails .detailsjet .hightight .inficon .infocards img {
  padding: 20px;
  width: 100%;
  height: 100%;
}
.clientJetDetails .detailsjet .hightight .inficon .infocards .cont {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #2f5634;
  display: none;
}
.clientJetDetails .detailsjet .hightight .inficon .infocards .cont h6 {
  text-transform: capitalize;
  font-size: 18px;
}
.clientJetDetails .detailsjet .hightight .inficon .infocards:hover img {
  display: none;
}
.clientJetDetails .detailsjet .hightight .inficon .infocards:hover .cont {
  display: flex;
}
.clientJetDetails .detailsjet .othr .cont {
  margin-bottom: 26px;
  padding: 10px 12px;
  background-color: #0a120b;
  border-bottom: 2px solid white;
}/*# sourceMappingURL=style.css.map */