header {
  padding: 0px 140px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  background: rgba(0, 0, 0, 0.33);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}
@media (max-width: 1200px) {
  header {
    padding: 0px 60px;
  }
}
@media (max-width: 992px) {
  header {
    padding: 0px 50px;
  }
}
@media (max-width: 768px) {
  header {
    padding: 0px 30px;
  }
}
@media (max-width: 576px) {
  header {
    padding: 0px 30px;
  }
}
@media (max-width: 400px) {
  header {
    padding: 0px 15px;
  }
}
@media (max-width: 992px) {
  header .logomain {
    width: 60px;
  }
}
@media (max-width: 470px) {
  header .logomain {
    width: 50px;
  }
}
@media (max-width: 435px) {
  header .logomain {
    width: 50px;
  }
}
header .iat {
  width: 45.87px;
  height: 28.06px;
}
@media (max-width: 470px) {
  header .iat {
    width: 45px;
  }
}
@media (max-width: 435px) {
  header .iat {
    width: 35px;
  }
}
header .nbaa {
  width: 93.07px;
  height: 24.55px;
}
@media (max-width: 470px) {
  header .nbaa {
    width: 75px;
  }
}
@media (max-width: 435px) {
  header .nbaa {
    width: 60px;
    height: 20.55px;
  }
}
header .btn1 {
  border-radius: 6px;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  border: 2px solid white;
  font-size: 20px;
  padding: 5px 15px;
  background: transparent;
}
@media (max-width: 992px) {
  header .btn1 {
    padding: 3px 10px;
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  header .btn1 {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  header .btn1 {
    font-size: 14px;
    padding: 3px 7px;
  }
}
header .btn1:hover {
  background: linear-gradient(180deg, #b97208 0%, #ffd800 100%);
}
header h2 {
  color: #fff;
  font-family: var(--Ubuntu);
  font-size: 25.385px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.538px;
  text-transform: uppercase;
}
@media (max-width: 1250px) {
  header h2 {
    font-size: 22px;
    letter-spacing: 0.538px;
  }
}
@media (max-width: 992px) {
  header h2 {
    font-size: 18px;
    letter-spacing: 0.538px;
  }
}
@media (max-width: 768px) {
  header h2 {
    font-size: 18px;
    letter-spacing: 0.538px;
    font-weight: 500;
  }
}
@media (max-width: 576px) {
  header h2 {
    font-size: 14px;
  }
}/*# sourceMappingURL=style.css.map */