.aboutuspage {
  display: block;
  background-color: black;
  width: 100%;
}
.aboutuspage .aboutheading {
  padding-top: 120px;
  padding-bottom: 60px;
}
@media (max-width: 768px) {
  .aboutuspage .aboutheading {
    padding-bottom: 10px;
  }
}
.aboutuspage .aboutheading h2 {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 65.217px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .aboutuspage .aboutheading h2 {
    font-size: 45px;
  }
}
.aboutuspage .aboutbody {
  padding: 60px 120px;
  color: white;
}
@media (max-width: 1200px) {
  .aboutuspage .aboutbody {
    padding: 60px 60px;
  }
}
@media (max-width: 992px) {
  .aboutuspage .aboutbody {
    padding: 60px 30px;
  }
}
@media (max-width: 768px) {
  .aboutuspage .aboutbody {
    padding: 20px 30px;
  }
}
.aboutuspage .aboutbody p {
  color: #fff;
  font-family: Ubuntu;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.aboutuspage .aboutbody h4 {
  padding: 20px 0px;
  color: #fff;
  font-family: Ubuntu;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.aboutuspage .aboutbody ul,
.aboutuspage .aboutbody ol {
  list-style: disc;
  padding-left: 2rem;
}
.aboutuspage .aboutbody ul li,
.aboutuspage .aboutbody ol li {
  color: #fff;
  font-family: Ubuntu;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
}
.aboutuspage .client .heading h2 {
  letter-spacing: 10px;
}
.aboutuspage .client .heading p {
  text-transform: none;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.aboutuspage .client .clientcont .cont {
  position: relative;
}
.aboutuspage .client .clientcont .cont .clientimg {
  filter: grayscale(1);
}
.aboutuspage .client .clientcont .cont .info {
  display: none;
  padding: 20px 8px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 1s ease-in-out;
}
.aboutuspage .client .clientcont .cont .info h4 {
  padding: 0 0;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.aboutuspage .client .clientcont .cont .info h6 {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.aboutuspage .client .clientcont .cont:hover .info {
  display: block;
  transition: 1s ease-in-out;
}
.aboutuspage .client .clientcont .cont:hover .clientimg {
  filter: grayscale(0);
}/*# sourceMappingURL=style.css.map */