.brand {
  background-color: black;
  padding: 60px 0;
}
@media (max-width: 576px) {
  .brand {
    padding: 30px 0px;
  }
}
.brand .heading {
  padding: 0px 30px;
}
.brand img {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100px;
  width: 240px;
  margin: 0px 30px;
}
@media (max-width: 576px) {
  .brand img {
    margin: 0px 15px;
    height: 50px;
    width: 150px;
  }
}
.brand .brandss {
  padding: 0px 40px !important;
}
.brand .brandss img {
  -o-object-fit: contain;
     object-fit: contain;
}
.brand .sbody {
  margin: 0px 90px;
  padding: 60px 0px 0px 0px;
  font-size: 1.125rem;
}
.brand .sbody .sliders .slick-prev:before {
  content: url("../../assets//images/testonomial/prev.svg");
}
.brand .sbody .sliders .slick-next:before {
  content: url("../../assets//images/testonomial/next.svg");
}
.brand .sbody .sliders .slick-prev {
  background-color: black;
  border-radius: 50%;
  left: -60px;
  width: 50px;
  height: 50px;
  z-index: 1 !important;
}
.brand .sbody .sliders .slick-next {
  background-color: black;
  border-radius: 50%;
  right: -60px;
  width: 50px;
  height: 50px;
  z-index: 1 !important;
}
.brand .sbody .contbtn1 {
  top: 50%;
  position: absolute;
  left: -90px;
}
.brand .sbody .contbtn1 img {
  width: 42px;
  height: 42px;
  filter: brightness(0.7);
}
.brand .sbody .contbtn2 {
  top: 50%;
  position: absolute;
  right: -90px;
}
.brand .sbody .contbtn2 img {
  width: 42px;
  height: 42px;
  filter: brightness(0.7);
}
.brand .sbody .scroller {
  max-width: 100%;
  position: relative;
}
.brand .sbody .scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.brand .sbody .scroller[data-animated=true] {
  overflow: hidden;
}
.brand .sbody .scroller[data-animated=true] .scroller__inner {
  width: -moz-max-content;
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}
.brand .sbody .scroller[data-direction=right] {
  --_animation-direction: reverse;
}
.brand .sbody .scroller[data-direction=left] {
  --_animation-direction: forwards;
}
.brand .sbody .btnrev {
  --_animation-direction: reverse;
}
.brand .sbody .btnnext {
  --_animation-direction: forwards;
}
.brand .sbody .scroller[data-speed=slow] {
  --_animation-duration: 420s;
}
.brand .sbody .scroller[data-speed=fast] {
  --_animation-duration: 110s;
}
@keyframes scroll {
  to {
    transform: translate(calc(-50% - 1rem));
  }
}
.brand .sbody .scroller:hover .scroller__inner {
  animation-play-state: paused;
}/*# sourceMappingURL=brand.css.map */