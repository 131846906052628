.unlocking {
  position: relative;
  background-color: black;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 60px;
  @media (max-width: 768px) {
    padding: 0px 30px;
  }

  .body-section {
    .heading {
      h2 {
        padding-bottom: 20px;
        @media (max-width: 1200px) {
          padding-top: 60px;
        }
        @media (max-width: 576px) {
          padding-top: 30px;
        }
      }
    }
    .cardbody {
      padding: 70px 120px;
      @media (max-width: 1200px) {
        padding: 70px 60px;
      }
      @media (max-width: 768px) {
        padding: 70px 30px;
      }
      @media (max-width: 576px) {
        padding: 70px 15px;
      }
      .cardcont {
        width: 353px;
        height: 352px;
        padding: 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        @media (max-width: 1665px) {
          height: 253px;
          width: 253px;
        }
        @media (max-width: 768px) {
          height: 203px;
          width: 203px;
          margin-bottom: 70px;
        }
        @media (max-width: 576px) {
          height: 150px;
          width: 150px;
          margin-bottom: 120px;
          padding: 0px 5px;
        }
        .card {
          background-color: #0e0e0e;
          background-image: url(../../assets/images/unlocking/bg1.png);
          background-position: center;
          background-repeat: repeat;
          background-size: cover;
          width: 353px;
          height: 352px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border: 1px solid rgba(255, 255, 255, 0.44);
          @media (max-width: 1665px) {
            height: 253px;
            width: 253px;
          }
          h4 {
            color: #fff;
            text-align: center;
            font-family: var(--ubuntu);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            @media (max-width: 768px) {
              font-size: 18px;
            }
            @media (max-width: 576px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
