.tourimages {
  padding-top: 160px;
  background-color: black;
  min-height: 100vh;
}
.tourimages .cont {
  padding: 0px 60px;
}
@media (max-width: 768px) {
  .tourimages .cont {
    padding: 0px 30px;
  }
}
@media (max-width: 576px) {
  .tourimages .cont {
    padding: 0px 15px;
  }
}
.tourimages .cont img {
  width: 300px;
  height: 300px;
}
@media (max-width: 768px) {
  .tourimages .cont img {
    width: 225px;
    height: 225px;
  }
}
@media (max-width: 576px) {
  .tourimages .cont img {
    width: 100%;
    height: 100%;
  }
}/*# sourceMappingURL=style.css.map */