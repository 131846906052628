.aboutuspage {
  display: block;
  background-color: black;
  width: 100%;
  .aboutheading {
    padding-top: 120px;
    padding-bottom: 60px;
    @media (max-width: 768px) {
      padding-bottom: 10px;
    }
    h2 {
      color: #fff;
      text-align: center;
      font-family: Ubuntu;
      font-size: 65.217px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      @media (max-width: 992px) {
        font-size: 45px;
      }
    }
  }
  .aboutbody {
    padding: 60px 120px;
    @media (max-width: 1200px) {
      padding: 60px 60px;
    }
    @media (max-width: 992px) {
      padding: 60px 30px;
    }
    @media (max-width: 768px) {
      padding: 20px 30px;
    }
    color: white;
    p {
      color: #fff;
      font-family: Ubuntu;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    h4 {
      padding: 20px 0px;
      color: #fff;
      font-family: Ubuntu;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    ul,
    ol {
      list-style: disc;
      padding-left: 2rem;
      li {
        color: #fff;
        font-family: Ubuntu;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }
    }
  }
  .client {
    .heading {
      h2 {
        letter-spacing: 10px;
      }
      p {
        text-transform: none;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
    }
    .clientcont {
      .cont {
        position: relative;
        .clientimg {
          filter: grayscale(1);
        }
        .info {
          display: none;
          padding: 20px 8px;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          transition: 1s ease-in-out;

          h4 {
            padding: 0 0;
            color: #fff;
            text-align: center;
            font-family: Ubuntu;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          h6 {
            color: #fff;
            text-align: center;
            font-family: Ubuntu;
            font-size: 20px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
          }
        }
      }
      .cont:hover .info {
        display: block;
        transition: 1s ease-in-out;
      }
      .cont:hover .clientimg {
        filter: grayscale(0);
      }
    }
  }
}
