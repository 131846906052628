.Compromise {
  background-color: black;
  padding: 60px 120px;
  @media (max-width: 992px) {
    padding: 60px 60px;
  }
  @media (max-width: 576px) {
    padding: 30px 30px;
  }

  .heading {
    padding: 0px 80px;
    text-align: center;
    @media (max-width: 768px) {
      padding: 0px 0px;
    }
  }

  .sbody {
    padding: 60px 0px 0px 0px;
    font-size: 1.125rem;
    .scroller {
      max-width: 100%;
    }

    .scroller__inner {
      padding-block: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .scroller[data-animated="true"] {
      overflow: hidden;
    }

    .scroller[data-animated="true"] .scroller__inner {
      width: max-content;
      flex-wrap: nowrap;
      animation: scroll var(--_animation-duration, 40s)
        var(--_animation-direction, forwards) linear infinite;
    }

    .scroller[data-direction="right"] {
      --_animation-direction: reverse;
    }

    .scroller[data-direction="left"] {
      --_animation-direction: forwards;
    }

    .scroller[data-speed="fast"] {
      --_animation-duration: 20s;
    }

    .scroller[data-speed="slow"] {
      --_animation-duration: 520s;
    }

    @keyframes scroll {
      to {
        transform: translate(calc(-50% - 0.5rem));
      }
    }
  }

  .sbody .scroller:hover .scroller__inner {
    animation-play-state: paused;
  }

  // .scroller {
  //   margin: 50px 0px;
  //   img {
  //     margin: 0px 20px;
  //   }
  // }
}
