.clientJetDetails {
  color: white;
  background-color: #0e1810;
  min-height: 100vh;
  @media (max-width: 576px) {
    min-height: 50vh;
  }

  .cjbanner {
    z-index: 2;
    width: 100%;
    height: 75vh;
    overflow-y: hidden;
    @media (max-width: 1025px) {
      margin-top: 0;
      margin-right: 0px;
    }
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 576px) {
      height: 50vh;
    }
    @media (max-width: 440px) {
      width: 100%;
    }
    .slider1 {
      img {
        height: 75vh;
        width: 100%;
        object-fit: cover;
        @media (max-width: 576px) {
          height: 50vh;
        }
      }
      .slick-dots {
        bottom: 75px;
      }
      .slick-dots li {
        width: 45px;
        height: 3px;
      }
      .slick-prev {
        left: 70px;
        width: 50px;
        height: 50px;
        z-index: 1 !important;
      }
      .slick-next {
        right: 70px;
        width: 50px;
        height: 50px;
        z-index: 1 !important;
      }

      @media (max-width: 576px) {
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          left: 25px;
          width: 50px;
          height: 50px;
          z-index: 1 !important;
        }
        .slick-dots {
          bottom: 25px;
        }
      }
      .slick-dots .slick-active {
        background-color: #7df08e;
      }

      .slick-dots li button {
        background-color: white;
        height: 3px;
        width: 40px;
        padding: 0px;
      }
      .slick-dots li button:before {
        content: "";
        height: 2px !important;
        width: 40px !important;
      }
      .slick-dots li.slick-active button {
        height: 2px !important;
      }
      .slick-dots li.slick-active button:before {
        background-color: #7df08e;
        height: 3px !important;
        width: 80px;
      }

      .slick-prev:before {
        content: url("../../assets//images/testonomial/prev.svg");
      }
      .slick-next:before {
        content: url("../../assets//images/testonomial/next.svg");
      }
      @media (max-width: 576px) {
        .slick-prev {
          top: 58%;
        }
        .slick-next {
          top: 58%;
        }
        .slick-dots li {
          width: auto;
        }
        .slick-dots li button {
          background-color: white;
          width: 20px;
          padding: 0px;
        }
        .slick-dots .slick-active {
          width: auto;
          background-color: #7df08e;
        }
        .slick-dots li button:before {
          content: "";
          height: 2px !important;
          width: 20px !important;
        }
      }
    }
  }
  .detailsjet {
    padding: 60px 120px;
    @media (max-width: 992px) {
      padding: 60px 60px;
    }
    @media (max-width: 768px) {
      padding: 60px 30px;
    }
    .heads {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 768px) {
        display: block;
      }
      .line {
        height: 1px;
        background-color: #606060;
        width: 100%;
        margin: 0px 20px;
      }
      p {
        white-space: nowrap;
        color: #606060;
        font-family: Ubuntu;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
      }
      h2 {
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
        white-space: nowrap;
        @media (max-width: 576px) {
          font-size: 24px;
        }
      }
    }
    .pdf {
      h4 {
        font-size: 30px;
        @media (max-width: 992px) {
          font-size: 20px;
        }
      }

      .pdformate {
        background-color: #7df08e;
        margin-top: 20px;
        padding: 7px 20xp;
        width: fit-content;
        color: black;
      }
    }
    .hightight {
      padding: 60px 0px;
      .inficon {
        background-color: #0e1810;
        .infocards {
          border: 2px solid white;
          margin: 20px 20px;
          width: 122px;
          height: 121px;
          overflow: hidden;
          border-radius: 50%;
          img {
            padding: 20px;
            width: 100%;
            height: 100%;
          }
          .cont {
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            background-color: #2f5634;
            display: none;
            h6 {
              text-transform: capitalize;
              font-size: 18px;
            }
          }
          &:hover img {
            display: none;
          }
          &:hover .cont {
            display: flex;
          }
        }
      }
    }
    .othr {
      .cont {
        margin-bottom: 26px;
        padding: 10px 12px;
        background-color: #0a120b;
        border-bottom: 2px solid white;
      }
    }
  }
}
