.contactus {
  height: 100%;
  padding: 60px 60px;
  overflow-y: hidden;
  background: #111;
  @media (max-width: 1200px) {
    padding: 60px 30px;
  }
  @media (max-width: 576px) {
    padding: 30px 30px;
  }
  .contcard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .crl {
      width: 76px;
      height: 76px;
      border: 4px solid #7df08e;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #7df08e;
      font-size: 60px;
      font-weight: 500;
    }
    h3 {
      color: #fff;
      font-size: 22px;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
      text-align: center;
      min-height: 105px;
      @media (max-width: 1024px) {
        min-height: auto;
      }
    }
    p {
      color: #fff;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
  }
}

.Contactusform {
  position: fixed;
  height: 100vh;
  left: 0;
  right: 0;
  transition: 1s ease-in;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: #0e1810;
  .head {
    background-color: #2f5634;
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .formbody {
    h5 {
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    padding: 60px 120px;
    .form-field {
      background-color: #0e1810;
      padding: 14px 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid #fff;
      input {
        padding: 14px 20px;
        background-color: #0e1810;
        width: 100%;
        color: #606060;
      }
    }
    .form-fieldphone {
      border-bottom: 1px solid white;
      input {
        padding: 14px 20px;
        background-color: #0e1810;
      }
    }
    textarea {
      width: 100%;
      background-color: #0e1810;
      padding: 14px 20px;
      color: #606060;
      height: 100px;
    }
  }
  .otpbody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    h5 {
      color: #fff;
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      letter-spacing: 3px;
      text-transform: uppercase;
    }
    p {
      padding: 20px;
      color: white;
      font-size: 20px;
    }
    input {
      margin: 50px 10px;
      border-bottom: 2px solid white;
      height: 50px;
      width: 50px !important;
      background-color: black;
      color: white;
    }
  }
  .thanks {
    padding: 30px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80%;
    flex-direction: column;
    img {
      height: 200px;
      width: 200px;
      margin-left: 30px;
    }
    h5 {
      font-size: 40px;
      color: white;
      text-transform: uppercase;
    }
    p {
      font-size: 20px;
      color: white;
    }
  }
}

.tsfbefore {
  transform: translateY(100%);
  transition: 1s ease-in;
}
.tsfafter {
  transition: 1s ease-in;
  transform: translateY(0%);
}
