.testimonial {
  background-color: black;
  padding: 60px 0px;
  overflow-x: hidden;
  .heading {
    padding: 0px 30px;
  }
  .sildertest {
    padding: 60px 0px;
    .cimg {
      overflow: hidden;
      img {
        height: 150px;
        border-radius: 50%;
        width: 150px;
        object-fit: cover;
      }
    }
    h4 {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    h6 {
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      padding-bottom: 30px;
    }
    p {
      text-align: center;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
    .dec {
      img {
        max-width: 25px;
        height: 20px;
      }
    }
    .slick-prev {
      left: 70px;
      z-index: 1 !important;
    }
    @media (max-width: 576px) {
      .slick-next {
        top: 30%;
        right: 40px;
      }
      .slick-prev {
        top: 30%;
        left: 40px;
        z-index: 1 !important;
      }
    }
    .slick-prev:before {
      z-index: 1 !important;

      content: url("../../assets//images/testonomial/prev.svg");
    }
    .slick-next {
      right: 70px;
      z-index: 9999;
    }
    @media (max-width: 576px) {
      .slick-next {
        top: 30%;
        right: 40px;
      }
      .slick-prev {
        top: 30%;
        left: 40px;
      }
    }
    .slick-next:before {
      content: url("../../assets//images/testonomial/next.svg");
    }
    .slick-dots {
      bottom: -70px;
    }
    .slick-dots li {
      width: 45px;
      height: 3px;
    }
    .slick-dots .slick-active {
      width: 80px;
      background-color: #7df08e;
    }
    @media (max-width: 576px) {
      .slick-dots li {
        width: auto;
        height: auto;
      }
      .slick-dots .slick-active {
        width: auto;
        background-color: #7df08e;
      }
    }

    .slick-dots li button {
      background-color: white;
      height: 3px;
      width: 40px;
      padding: 0px;
    }
    .slick-dots li button:before {
      content: "";
      height: 2px !important;
      width: 40px !important;
    }
    .slick-dots li.slick-active button {
      height: 2px !important;
    }
    .slick-dots li.slick-active button:before {
      background-color: #7df08e;
      height: 3px !important;
      width: 80px;
    }
  }
}
