.dealwith .banner {
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: black;
}
@media (max-width: 768px) {
  .dealwith .banner {
    height: 60vh;
  }
}
.dealwith .banner img {
  width: 100% !important;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 768px) {
  .dealwith .banner img {
    height: 60vh;
  }
}
.dealwith .banner .scroll-down {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 3px;
  height: 100px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.3019607843);
}
.dealwith .banner .scroll-down .point {
  height: 30px;
  position: relative;
  width: 3px;
  background-color: white;
  animation: downward 1s linear infinite;
}
.dealwith .jetcont {
  background-color: black;
  padding: 60px 0px 0px 0px;
}
.dealwith .jetcont .heading {
  padding: 0px 160px;
}
@media (max-width: 1200px) {
  .dealwith .jetcont .heading {
    padding: 0px 60px;
  }
}
@media (max-width: 576px) {
  .dealwith .jetcont .heading {
    padding: 0px 30px;
  }
}
.dealwith .jetcont .slider {
  padding-top: 70px;
  padding-left: 180px;
  padding-bottom: 100px;
  color: white;
}
@media (max-width: 1200px) {
  .dealwith .jetcont .slider {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 768px) {
  .dealwith .jetcont .slider {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.dealwith .jetcont .slider .cards {
  display: flex;
  justify-content: start;
  align-items: center;
  padding-bottom: 60px;
}
@media (max-width: 1740px) {
  .dealwith .jetcont .slider .cards {
    justify-content: center;
  }
}
.dealwith .jetcont .slider .cards .title {
  font-size: 24px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .dealwith .jetcont .slider .cards .title {
    font-size: 20px;
  }
}
.dealwith .jetcont .slider .cards h4:nth-child(2)::first-line {
  font-weight: bold;
  color: green;
}
.dealwith .jetcont .slider .cards h6 {
  color: white;
  font-size: 20px;
  font-weight: 300;
}
@media (max-width: 992px) {
  .dealwith .jetcont .slider .cards h6 {
    font-size: 18px;
  }
}
.dealwith .jetcont .slider .slick-prev {
  left: 0px;
  z-index: 9999;
  bottom: 0;
  top: 100%;
}
.dealwith .jetcont .slider .slick-prev:before {
  content: url("../../assets//images/testonomial/prev.svg");
}
.dealwith .jetcont .slider .slick-next {
  bottom: 0;
  left: 70px;
  z-index: 9999;
  top: 100%;
}
.dealwith .jetcont .slider .slick-next:before {
  content: url("../../assets//images/testonomial/next.svg");
}
.dealwith .jetcont .slider .slick-dots {
  position: absolute;
  width: auto;
  text-align: left;
  left: 160px;
  bottom: -15px;
}
.dealwith .jetcont .slider .slick-dots li {
  width: 45px;
  height: 3px;
}
.dealwith .jetcont .slider .slick-dots .slick-active {
  width: 80px;
  background-color: #7df08e;
}
@media (max-width: 768px) {
  .dealwith .jetcont .slider .slick-dots li {
    width: 20px;
    height: 3px;
  }
  .dealwith .jetcont .slider .slick-dots .slick-active {
    width: 25px;
    background-color: #7df08e;
  }
}
.dealwith .jetcont .slider .slick-dots li button {
  background-color: white;
  height: 3px;
  width: 40px;
  padding: 0px;
}
.dealwith .jetcont .slider .slick-dots li button:before {
  content: "";
  height: 2px !important;
  width: 40px !important;
}
.dealwith .jetcont .slider .slick-dots li.slick-active button {
  height: 2px !important;
}
.dealwith .jetcont .slider .slick-dots li.slick-active button:before {
  background-color: #7df08e;
  height: 3px !important;
  width: 80px;
}
@media (max-width: 576px) {
  .dealwith .jetcont .slider .slick-dots li button {
    width: 10px;
  }
  .dealwith .jetcont .slider .slick-dots li button:before {
    width: 10px !important;
  }
  .dealwith .jetcont .slider .slick-dots .slick-active {
    width: 10px;
  }
  .dealwith .jetcont .slider .slick-dots li {
    width: 10px;
  }
}
.dealwith .imgcont {
  background-color: black;
}
.dealwith .imgcont img {
  height: 200px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  overflow: hidden;
}
@media (max-width: 576px) {
  .dealwith .imgcont img {
    height: 150px;
  }
}
.dealwith .Content {
  background-color: black;
  padding: 0px 60px 30px 60px;
  color: white;
}
@media (max-width: 1200px) {
  .dealwith .Content {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 576px) {
  .dealwith .Content {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.dealwith .Content ul {
  padding-top: 16px;
  list-style: disc;
  padding-left: 32px;
}
.dealwith .Content ul li {
  line-height: 30px;
}
.dealwith .Content h3 {
  font-size: 36px;
}
.dealwith .Content h4 {
  padding-top: 20px;
  font-size: 26px;
}
.dealwith .Content h5 {
  font-size: 22px;
  padding: 16px 0;
}
.dealwith .Content p {
  font-size: 16px;
  line-height: 24px;
}

@keyframes downward {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}/*# sourceMappingURL=style.css.map */