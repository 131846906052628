.tourimages {
  padding-top: 160px;
  background-color: black;
  min-height: 100vh;
  .cont {
    padding: 0px 60px;
    @media (max-width: 768px) {
      padding: 0px 30px;
    }
    @media (max-width: 576px) {
      padding: 0px 15px;
    }
    img {
      width: 300px;
      height: 300px;
      @media (max-width: 768px) {
        width: 225px;
        height: 225px;
      }
      @media (max-width: 576px) {
        width: 100%;
        height: 100%;
      }
    }
  }
}
