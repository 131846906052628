.banner {
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: black;
  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .text-section {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    h2 {
      color: white;
      text-align: center;
      font-family: var(--Bangers);
      font-size: 80.595px;
      font-style: normal;
      font-weight: 400;
      line-height: 112px;
      text-transform: uppercase;
      @media (max-width: 992px) {
        font-size: 60px;
        line-height: 50px;
      }
      @media (max-width: 576px) {
        font-size: 40px;
        line-height: 70px;
      }
    }
    h1 {
      color: var(--White, #fff);
      font-family: Bangers;
      font-size: 121.53px;
      font-style: normal;
      font-weight: 400;
      line-height: 112px;
      text-transform: uppercase;
      @media (max-width: 992px) {
        font-size: 90px;
      }
      @media (max-width: 576px) {
        font-size: 60px;
        line-height: 30px;
      }
      @media (max-width: 330px) {
        font-size: 60px;
        line-height: 30px;
        line-height: 50px;
      }
    }
    h3 {
      color: var(--Green, #7df08e);
      text-align: center;
      text-shadow: 4px -2px 3px rgba(0, 0, 0, 0.3);
      font-family: Bilbo Swash Caps;
      font-size: 130.537px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: -50px;
      @media (max-width: 992px) {
        font-size: 80px;
      }
      @media (max-width: 576px) {
        font-size: 40px;
        margin-top: 0px;
      }
    }
  }
  .scroll-down {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 3px;
    height: 100px;
    margin: auto;
    background-color: #ffffff4d;
    .point {
      height: 30px;
      position: relative;
      width: 3px;
      background-color: white;
      animation: downward 1s linear infinite;
    }
  }
}

@keyframes downward {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}
