.tour {
  background-color: #000;
}
.tour .tourbanner {
  height: 70vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: black;
}
.tour .tourbanner video {
  width: 100%;
  height: 70vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.tour .tourbanner .text-section {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.tour .tourbanner .text-section h1 {
  color: var(--White, #fff);
  font-family: Bangers;
  font-size: 121.53px;
  font-style: normal;
  font-weight: 400;
  line-height: 112px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .tour .tourbanner .text-section h1 {
    font-size: 90px;
  }
}
@media (max-width: 576px) {
  .tour .tourbanner .text-section h1 {
    font-size: 60px;
    line-height: 30px;
  }
}
@media (max-width: 330px) {
  .tour .tourbanner .text-section h1 {
    font-size: 60px;
    line-height: 30px;
    line-height: 50px;
  }
}
.tour .tourbanner .text-section h3 {
  color: var(--Green, #7df08e);
  text-align: center;
  text-shadow: 4px -2px 3px rgba(0, 0, 0, 0.3);
  font-family: Bilbo Swash Caps;
  font-size: 130.537px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -70px;
}
@media (max-width: 992px) {
  .tour .tourbanner .text-section h3 {
    font-size: 80px;
  }
}
@media (max-width: 576px) {
  .tour .tourbanner .text-section h3 {
    font-size: 40px;
    margin-top: 0px;
  }
}
.tour .tourbanner .text-section .arrowt {
  position: absolute;
  top: -85%;
}
.tour .tourbody {
  padding: 60px 120px;
}
@media (max-width: 1200px) {
  .tour .tourbody {
    padding: 60px 30px;
  }
}
.tour .tourbody .upcom h3 {
  white-space: nowrap;
  color: #fff;
  font-family: Ubuntu;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.tour .tourbody .upcom .line {
  margin: 0px 15px;
  width: 100%;
  height: 2px;
  background-color: #2e2e2e;
}
.tour .tourbody .upcom p {
  white-space: nowrap;
  color: #606060;
  font-family: Ubuntu;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
.tour .tourbody .tourlist .container {
  color: white;
}
.tour .tourbody .tourlist .container .flex-col {
  border: 1px solid #2e2e2e;
}
.tour .tourbody .tourlist .container .slider1 {
  overflow: hidden;
  width: 500px;
  height: 300px;
}
@media (max-width: 576px) {
  .tour .tourbody .tourlist .container .slider1 {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .tour .tourbody .tourlist .container .slider1 {
    width: 350px;
  }
}
.tour .tourbody .tourlist .container .slider1 img {
  width: 100%;
  height: 300px;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
@media (max-width: 992px) {
  .tour .tourbody .tourlist .container .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.tour .tourbody .tourlist .container .details h3 {
  color: var(--White, #fff);
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.33px;
  text-transform: uppercase;
}
.tour .tourbody .tourlist .container .details .iconlist {
  margin: 20px 0px;
}
.tour .tourbody .tourlist .container .details .iconlist img {
  width: 60px;
  height: 60px;
  margin-right: 20px;
}
.tour .tourbody .tourlist .container .details .btn1 {
  margin-top: 16px;
}
.tour .tourbody .tourlist .container .details .btn1 button {
  padding: 7px 10px 7px 40px;
  border-radius: 16.914px;
  border: 0.846px solid #fff;
  background: linear-gradient(180deg, #b97208 0%, #ffd800 100%);
  box-shadow: 8.66px 8.66px 16.238px 0px rgba(20, 21, 29, 0.6), -6.495px -6.495px 16.238px 0px rgba(48, 50, 64, 0.6);
}
.tour .tourbody .countrylist {
  padding: 60px 0px;
}
@media (max-width: 1200px) {
  .tour .tourbody .countrylist {
    padding: 60px 60px;
  }
}
@media (max-width: 650px) {
  .tour .tourbody .countrylist {
    padding: 0px 30px;
  }
}
.tour .tourbody .countrylist .heading {
  padding-bottom: 60px;
}
.tour .tourbody .countrylist .heading h2 {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 10px;
  text-transform: uppercase;
}
.tour .tourbody .countrylist .heading p {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 17px;
  font-weight: 300;
  font-style: normal;
  line-height: normal;
  padding: 0px 100px;
}
@media (max-width: 768px) {
  .tour .tourbody .countrylist .heading p {
    padding: 0;
  }
}
.tour .tourbody .countrylist .slider2 {
  position: relative;
  padding: 0px 60px;
}
@media (max-width: 768px) {
  .tour .tourbody .countrylist .slider2 {
    padding: 0px 20px;
  }
}
.tour .tourbody .countrylist .slider2 .contbody .wraper .text {
  width: 100%;
  opacity: 0.8;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
  position: absolute;
  bottom: 0px;
}
.tour .tourbody .countrylist .slider2 .contbody .wraper .text h3 {
  padding: 10px 0px;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 10px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .tour .tourbody .countrylist .slider2 .contbody .wraper .text h3 {
    font-size: 22px;
  }
}
.tour .tourbody .countrylist .slider2 .contbody .wraper .bgg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 86, 52, 0.7019607843);
  transform: translateY(100%);
}
.tour .tourbody .countrylist .slider2 .contbody .wraper:hover .bgg {
  transform: translateY(0%);
}
.tour .tourbody .countrylist .slider2 .slick-prev:before {
  content: url("../../assets//images/testonomial/prev.svg");
}
.tour .tourbody .countrylist .slider2 .slick-next:before {
  content: url("../../assets//images/testonomial/next.svg");
}/*# sourceMappingURL=style.css.map */