.dealwith {
  .banner {
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: black;
    @media (max-width: 768px) {
      height: 60vh;
    }
    img {
      width: 100% !important;
      height: 100vh;
      object-fit: cover;
      @media (max-width: 768px) {
        height: 60vh;
      }
    }

    .scroll-down {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 3px;
      height: 100px;
      margin: auto;
      background-color: #ffffff4d;
      .point {
        height: 30px;
        position: relative;
        width: 3px;
        background-color: white;
        animation: downward 1s linear infinite;
      }
    }
  }
  .jetcont {
    background-color: black;
    padding: 60px 0px 0px 0px;
    .heading {
      padding: 0px 160px;
      @media (max-width: 1200px) {
        padding: 0px 60px;
      }
      @media (max-width: 576px) {
        padding: 0px 30px;
      }
    }
    .slider {
      padding-top: 70px;
      padding-left: 180px;
      padding-bottom: 100px;
      color: white;
      @media (max-width: 1200px) {
        padding-left: 60px;
        padding-right: 60px;
      }
      @media (max-width: 768px) {
        padding-left: 30px;
        padding-right: 30px;
      }
      .cards {
        display: flex;
        justify-content: start;
        align-items: center;
        padding-bottom: 60px;
        @media (max-width: 1740px) {
          justify-content: center;
        }
        .title {
          font-size: 24px;
          text-transform: uppercase;
          @media (max-width: 992px) {
            font-size: 20px;
          }
        }
        h4:nth-child(2)::first-line {
          font-weight: bold;
          color: green;
        }

        h6 {
          color: white;
          font-size: 20px;
          font-weight: 300;
          @media (max-width: 992px) {
            font-size: 18px;
          }
        }
      }
      .slick-prev {
        left: 0px;
        z-index: 9999;
        bottom: 0;
        top: 100%;
      }
      .slick-prev:before {
        content: url("../../assets//images/testonomial/prev.svg");
      }
      .slick-next {
        bottom: 0;
        left: 70px;
        z-index: 9999;
        top: 100%;
      }
      .slick-next:before {
        content: url("../../assets//images/testonomial/next.svg");
      }
      .slick-dots {
        position: absolute;
        width: auto;
        text-align: left;
        left: 160px;
        bottom: -15px;
      }
      .slick-dots li {
        width: 45px;
        height: 3px;
      }

      .slick-dots .slick-active {
        width: 80px;
        background-color: #7df08e;
      }
      @media (max-width: 768px) {
        .slick-dots li {
          width: 20px;
          height: 3px;
        }
        .slick-dots .slick-active {
          width: 25px;
          background-color: #7df08e;
        }
      }

      .slick-dots li button {
        background-color: white;
        height: 3px;
        width: 40px;
        padding: 0px;
      }
      .slick-dots li button:before {
        content: "";
        height: 2px !important;
        width: 40px !important;
      }
      .slick-dots li.slick-active button {
        height: 2px !important;
      }
      .slick-dots li.slick-active button:before {
        background-color: #7df08e;
        height: 3px !important;
        width: 80px;
      }
      @media (max-width: 576px) {
        .slick-dots li button {
          width: 10px;
        }
        .slick-dots li button:before {
          width: 10px !important;
        }
        .slick-dots .slick-active {
          width: 10px;
        }
        .slick-dots li {
          width: 10px;
        }
      }
    }
  }
  .imgcont {
    background-color: black;
    img {
      height: 200px;
      width: 100%;
      object-fit: cover;
      overflow: hidden;
      @media (max-width: 576px) {
        height: 150px;
      }
    }
  }
  .Content {
    background-color: black;
    padding: 0px 60px 30px 60px;
    color: white;
    @media (max-width: 1200px) {
      padding-left: 60px;
      padding-right: 60px;
    }
    @media (max-width: 576px) {
      padding-left: 30px;
      padding-right: 30px;
    }
    ul {
      padding-top: 16px;
      list-style: disc;
      padding-left: 32px;
      li {
        line-height: 30px;
      }
    }
    h3 {
      font-size: 36px;
    }
    h4 {
      padding-top: 20px;
      font-size: 26px;
      // font-weight: 700;
    }
    h5 {
      font-size: 22px;
      padding: 16px 0;
    }
    p {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

@keyframes downward {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}
