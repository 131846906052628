.tour {
  background-color: #000;
  .tourbanner {
    height: 70vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: black;
    video {
      width: 100%;
      height: 70vh;
      object-fit: cover;
    }
    .text-section {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      h1 {
        color: var(--White, #fff);
        font-family: Bangers;
        font-size: 121.53px;
        font-style: normal;
        font-weight: 400;
        line-height: 112px;
        text-transform: uppercase;
        @media (max-width: 992px) {
          font-size: 90px;
        }
        @media (max-width: 576px) {
          font-size: 60px;
          line-height: 30px;
        }
        @media (max-width: 330px) {
          font-size: 60px;
          line-height: 30px;
          line-height: 50px;
        }
      }
      h3 {
        color: var(--Green, #7df08e);
        text-align: center;
        text-shadow: 4px -2px 3px rgba(0, 0, 0, 0.3);
        font-family: Bilbo Swash Caps;
        font-size: 130.537px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -70px;
        @media (max-width: 992px) {
          font-size: 80px;
        }
        @media (max-width: 576px) {
          font-size: 40px;
          margin-top: 0px;
        }
      }
      .arrowt {
        position: absolute;
        top: -85%;
      }
    }
  }
  .tourbody {
    padding: 60px 120px;
    @media (max-width: 1200px) {
      padding: 60px 30px;
    }

    .upcom {
      h3 {
        white-space: nowrap;
        color: #fff;
        font-family: Ubuntu;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }
      .line {
        margin: 0px 15px;
        width: 100%;
        height: 2px;
        background-color: #2e2e2e;
      }
      p {
        white-space: nowrap;

        color: #606060;
        font-family: Ubuntu;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
      }
    }
    .tourlist {
      .container {
        color: white;

        .flex-col {
          border: 1px solid #2e2e2e;
        }
        .slider1 {
          overflow: hidden;
          width: 500px;
          height: 300px;
          @media (max-width: 576px) {
            width: 400px;
          }
          @media (max-width: 450px) {
            width: 350px;
          }
          img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            object-position: center;
          }
        }
        .details {
          @media (max-width: 992px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }

          h3 {
            color: var(--White, #fff);
            font-size: 22px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.33px;
            text-transform: uppercase;
          }
          .iconlist {
            margin: 20px 0px;
            img {
              width: 60px;
              height: 60px;
              margin-right: 20px;
            }
          }
          .btn1 {
            margin-top: 16px;
            button {
              padding: 7px 10px 7px 40px;
              border-radius: 16.914px;
              border: 0.846px solid #fff;
              background: linear-gradient(180deg, #b97208 0%, #ffd800 100%);
              box-shadow: 8.66px 8.66px 16.238px 0px rgba(20, 21, 29, 0.6),
                -6.495px -6.495px 16.238px 0px rgba(48, 50, 64, 0.6);
            }
          }
        }
      }
    }
    .countrylist {
      padding: 60px 0px;
      @media (max-width: 1200px) {
        padding: 60px 60px;
      }
      @media (max-width: 650px) {
        padding: 0px 30px;
      }
      .heading {
        padding-bottom: 60px;
        h2 {
          color: #fff;
          text-align: center;
          font-family: Ubuntu;
          font-size: 30px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          letter-spacing: 10px;
          text-transform: uppercase;
        }
        p {
          color: #fff;
          text-align: center;
          font-family: Ubuntu;
          font-size: 17px;
          font-weight: 300;
          font-style: normal;
          line-height: normal;
          padding: 0px 100px;
          @media (max-width: 768px) {
            padding: 0;
          }
        }
      }
      .slider2 {
        position: relative;
        padding: 0px 60px;
        @media (max-width: 768px) {
          padding: 0px 20px;
        }
        .contbody {
          .wraper {
            .text {
              width: 100%;
              opacity: 0.8;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) 0%,
                #000 100%
              );
              position: absolute;
              bottom: 0px;
              h3 {
                padding: 10px 0px;
                color: #fff;
                text-align: center;
                font-family: Ubuntu;
                font-size: 30px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 10px;
                text-transform: uppercase;
                @media (max-width: 768px) {
                  font-size: 22px;
                }
              }
            }
            .bgg {
              position: absolute;
              inset: 0;
              width: 100%;
              height: 100%;
              background-color: #2f5634b3;
              transform: translateY(100%);
            }
          }
          .wraper:hover .bgg {
            transform: translateY(00%);
          }
        }
        .slick-prev:before {
          content: url("../../assets//images/testonomial/prev.svg");
        }
        .slick-next:before {
          content: url("../../assets//images/testonomial/next.svg");
        }
      }
    }
  }
}
