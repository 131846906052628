.Dealwith {
  height: 100%;
  position: relative;
  width: 100%;
  background-color: black;
}
@media (max-width: 1025px) {
  .Dealwith {
    padding: 0px 60px;
  }
}
@media (max-width: 576px) {
  .Dealwith {
    padding: 30px 0px;
  }
}
@media (max-width: 1025px) {
  .Dealwith {
    overflow: auto;
    height: 100%;
  }
}
.Dealwith .bgvideo {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1025px) {
  .Dealwith .bgvideo {
    height: 130vh;
  }
}
@media (max-width: 640px) {
  .Dealwith .bgvideo {
    height: 230vh;
  }
}
@media (max-width: 340px) {
  .Dealwith .bgvideo {
    height: 220vh;
  }
}
.Dealwith .bgvideo video {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
}
.Dealwith .body-section {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Dealwith .body-section .heading h2 {
  padding: 60px 0px;
}
@media (max-width: 768px) {
  .Dealwith .body-section .heading h2 {
    padding: 0px 0px 60px 0px;
  }
}
.Dealwith .body-section .dealcardbody {
  cursor: pointer;
  padding: 0px 200px;
}
@media (max-width: 1400px) {
  .Dealwith .body-section .dealcardbody {
    padding: 0px 0px;
  }
}
@media (max-width: 768px) {
  .Dealwith .body-section .dealcardbody {
    padding: 0px 30px;
  }
}
.Dealwith .body-section .dealcardbody .dealcardcont {
  width: 100%;
  padding: 0px 20px;
  height: 500px;
  position: relative;
}
@media (max-width: 1500px) {
  .Dealwith .body-section .dealcardbody .dealcardcont {
    height: 430px;
  }
}
.Dealwith .body-section .dealcardbody .dealcardcont .name {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
}
.Dealwith .body-section .dealcardbody .dealcardcont .name h3 {
  color: white;
  text-align: center;
  font-family: Ubuntu;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.Dealwith .body-section .dealcardbody .dealcardcont .dealcard {
  height: 100%;
  background-color: white;
  overflow: hidden;
}
.Dealwith .body-section .dealcardbody .dealcardcont .dealcard .hoverimg {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%;
}
.Dealwith .body-section .dealcardbody .dealcardcont .dealcard .hoverimg:hover {
  transform: scale(1.3);
}
.Dealwith .body-section .dealcardbody .dealcardcont .dealcard .hovervideo {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=dealwith.css.map */