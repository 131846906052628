.unlocking_deatils {
  width: 100%;
  background-color: black;
  background-image: url("../../assets/images/img/getbg.png");
  background-position: center;
  background-size: cover;
  padding-top: 140px;
  color: white;
}
.unlocking_deatils .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0px 200px;
}
@media (max-width: 1400px) {
  .unlocking_deatils .content {
    padding: 0px 100px;
  }
}
@media (max-width: 992px) {
  .unlocking_deatils .content {
    padding: 0px 60px;
  }
}
@media (max-width: 768px) {
  .unlocking_deatils .content {
    padding: 0px 30px;
  }
}
.unlocking_deatils .content .pdfcont {
  width: 402px;
  height: 75px;
  border-radius: 11px;
  border: 4.7px solid rgba(255, 255, 255, 0.3);
  align-items: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 28px;
  margin-bottom: 20px;
  margin-top: 30px;
  cursor: pointer;
}
@media (max-width: 576px) {
  .unlocking_deatils .content .pdfcont {
    width: 100%;
    padding: 0px 10px;
  }
  .unlocking_deatils .content .pdfcont p {
    font-size: 14px;
  }
}
.unlocking_deatils .content .mainheading {
  padding-top: 80px;
  font-size: 30px;
}
@media (max-width: 576px) {
  .unlocking_deatils .content .mainheading {
    padding-top: 40px;
  }
}
.unlocking_deatils .content .lines {
  height: 2px;
  width: 148px;
  background-color: white;
  opacity: 30%;
  border-radius: 30px;
  margin: 20px 25px;
}
.unlocking_deatils .content p {
  font-size: 17px;
  font-weight: 300;
}
.unlocking_deatils .imgcont {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px;
}
.unlocking_deatils .imgcont img {
  width: 25%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 202px;
}
@media (max-width: 1400px) {
  .unlocking_deatils .imgcont img {
    height: 202px;
  }
}
@media (max-width: 992px) {
  .unlocking_deatils .imgcont img {
    width: 50%;
  }
}
@media (max-width: 576px) {
  .unlocking_deatils .imgcont img {
    width: 100%;
    height: 150px;
    padding: 0px 30px;
  }
}/*# sourceMappingURL=style.css.map */