.jet {
  background-color: black;
  padding: 0px 100px;
  overflow-x: hidden;
  @media (max-width: 1200px) {
    padding: 0px 10px;
  }
  .heading {
    h2 {
      white-space: nowrap;
    }
    p {
      color: var(--Color-Brand-Grey-Font, #898686);
      font-family: var(--ubuntu);
      font-size: 13px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: uppercase;
      white-space: nowrap;
    }
    .line {
      width: 100%;
      height: 2px;
      background-color: #2e2e2e;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  .silderjet {
    padding: 100px 20px;
    color: white;
    .cards {
      display: flex;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 28px;
        text-transform: uppercase;
        @media (max-width: 1200px) {
          font-size: 24px;
        }
        @media (max-width: 768px) {
          font-size: 20px;
        }
      }
      h4:nth-child(2)::first-line {
        font-weight: bold;
        color: green;
      }

      h6 {
        color: white;
        font-size: 20px;
        @media (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
    .slick-prev {
      left: 0px;
      z-index: 1 !important;
      @media (max-width: 576px) {
        left: -25px;
      }
    }
    .slick-prev:before {
      content: url("../../assets//images/testonomial/prev.svg");
    }
    .slick-next {
      right: 0px;
      z-index: 1 !important;
    }
    .slick-next:before {
      content: url("../../assets//images/testonomial/next.svg");
    }
    .slick-dots {
      bottom: -70px;
    }
    .slick-dots li {
      width: 45px;
      height: 3px;
    }
    .slick-dots .slick-active {
      width: 80px;
      background-color: #7df08e;
    }

    .slick-dots li button {
      background-color: white;
      height: 3px;
      width: 40px;
      padding: 0px;
    }
    .slick-dots li button:before {
      content: "";
      height: 2px !important;
      width: 40px !important;
    }
    .slick-dots li.slick-active button {
      height: 2px !important;
    }
    .slick-dots li.slick-active button:before {
      background-color: #7df08e;
      height: 3px !important;
      width: 80px;
    }
    @media (max-width: 576px) {
      .slick-dots li {
        width: auto;
      }
      .slick-dots li button {
        background-color: white;
        width: 20px;
        padding: 0px;
      }
      .slick-dots .slick-active {
        width: auto;
        background-color: #7df08e;
      }
      .slick-dots li button:before {
        content: "";
        height: 2px !important;
        width: 20px !important;
      }
    }
  }
}
