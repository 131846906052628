.terms {
  padding-top: 180px;
  background-color: #000000;
  color: white;
  padding: 160px 60px 60px 60px;
  min-height: 100vh;
}
@media (max-width: 576px) {
  .terms {
    padding: 160px 30px 30px 30px;
  }
}
.terms p {
  font-size: 18px;
  line-height: 25px;
  padding-bottom: 20px;
}
.terms p span {
  font-size: 18px !important;
}/*# sourceMappingURL=style.css.map */