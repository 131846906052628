.jet {
  background-color: black;
  padding: 0px 100px;
  overflow-x: hidden;
}
@media (max-width: 1200px) {
  .jet {
    padding: 0px 10px;
  }
}
.jet .heading h2 {
  white-space: nowrap;
}
.jet .heading p {
  color: var(--Color-Brand-Grey-Font, #898686);
  font-family: var(--ubuntu);
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  white-space: nowrap;
}
.jet .heading .line {
  width: 100%;
  height: 2px;
  background-color: #2e2e2e;
}
@media (max-width: 768px) {
  .jet .heading .line {
    display: none;
  }
}
.jet .silderjet {
  padding: 100px 20px;
  color: white;
}
.jet .silderjet .cards {
  display: flex;
  justify-content: center;
  align-items: center;
}
.jet .silderjet .cards .title {
  font-size: 28px;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .jet .silderjet .cards .title {
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .jet .silderjet .cards .title {
    font-size: 20px;
  }
}
.jet .silderjet .cards h4:nth-child(2)::first-line {
  font-weight: bold;
  color: green;
}
.jet .silderjet .cards h6 {
  color: white;
  font-size: 20px;
}
@media (max-width: 768px) {
  .jet .silderjet .cards h6 {
    font-size: 16px;
  }
}
.jet .silderjet .slick-prev {
  left: 0px;
  z-index: 1 !important;
}
@media (max-width: 576px) {
  .jet .silderjet .slick-prev {
    left: -25px;
  }
}
.jet .silderjet .slick-prev:before {
  content: url("../../assets//images/testonomial/prev.svg");
}
.jet .silderjet .slick-next {
  right: 0px;
  z-index: 1 !important;
}
.jet .silderjet .slick-next:before {
  content: url("../../assets//images/testonomial/next.svg");
}
.jet .silderjet .slick-dots {
  bottom: -70px;
}
.jet .silderjet .slick-dots li {
  width: 45px;
  height: 3px;
}
.jet .silderjet .slick-dots .slick-active {
  width: 80px;
  background-color: #7df08e;
}
.jet .silderjet .slick-dots li button {
  background-color: white;
  height: 3px;
  width: 40px;
  padding: 0px;
}
.jet .silderjet .slick-dots li button:before {
  content: "";
  height: 2px !important;
  width: 40px !important;
}
.jet .silderjet .slick-dots li.slick-active button {
  height: 2px !important;
}
.jet .silderjet .slick-dots li.slick-active button:before {
  background-color: #7df08e;
  height: 3px !important;
  width: 80px;
}
@media (max-width: 576px) {
  .jet .silderjet .slick-dots li {
    width: auto;
  }
  .jet .silderjet .slick-dots li button {
    background-color: white;
    width: 20px;
    padding: 0px;
  }
  .jet .silderjet .slick-dots .slick-active {
    width: auto;
    background-color: #7df08e;
  }
  .jet .silderjet .slick-dots li button:before {
    content: "";
    height: 2px !important;
    width: 20px !important;
  }
}/*# sourceMappingURL=jets.css.map */