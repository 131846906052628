.jetdetails {
  .banners {
    height: 100vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: black;
    .bg {
      img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
        object-position: 48% 51%;
      }
    }
    .bgjetg700 {
      img {
        width: 100%;
        height: 100vh;
        filter: brightness(0.8);
        object-fit: cover;
        object-position: 48% 100%;
        @media (max-width: 992px) {
          object-position: 70% 100%;
        }
        @media (max-width: 768px) {
          object-position: 80% 100%;
        }
        @media (max-width: 576px) {
          object-position: 90% 51%;
        }
      }
    }
    .text-section {
      margin-bottom: 75px;
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      padding: 0px 30px;
      justify-content: end;
      flex-direction: column;
      .texthead {
        margin-top: 60px;
        display: flex;
        justify-content: center;
        text-align: center;
        @media (max-width: 400px) {
          display: block;
        }
        h2 {
          color: #fff;
          font-family: Ubuntu;
          font-size: 65.217px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          @media (max-width: 768px) {
            font-size: 40px;
          }
        }
        .span {
          padding-right: 10px;
          color: #fff;
          text-align: center;
          font-family: Ubuntu;
          font-size: 65.217px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
          @media (max-width: 768px) {
            font-size: 40px;
          }
        }
      }
      h3 {
        color: #fff;
        text-align: center;
        font-family: Ubuntu;
        font-size: 65.217px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        text-transform: uppercase;
        @media (max-width: 768px) {
          font-size: 50px;
        }
      }
    }
    .scroll-down {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 3px;
      height: 100px;
      margin: auto;
      background-color: #ffffff4d;
      .point {
        height: 30px;
        position: relative;
        width: 3px;
        background-color: white;
        animation: downward 1s linear infinite;
      }
    }
  }

  .best {
    background-color: #020002;
    padding: 60px 120px;
    @media (max-width: 1200px) {
      padding: 60px 60px;
    }

    .cont {
      .contbody {
        padding: 60px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media (max-width: 768px) {
          padding: 0px 0px 60px 0px;
        }
        .imgcont {
          width: 223px;
          height: 223px;
          background-color: #0e1810;
          display: grid;
          place-items: center;
          border-radius: 50%;
          @media (max-width: 1200px) {
            width: 180px;
            height: 180px;
          }
        }
        p {
          padding-top: 24px;
          color: white;
          text-align: center;
          font-size: 22px;
          text-transform: uppercase;
          padding: 0px 20px;
          @media (max-width: 1200px) {
            font-size: 18px;
            margin-top: 20px;
          }
        }
      }
    }
  }

  .jetslider {
    width: 100%;
    overflow: hidden;
    background-color: black;
    .sldiers {
      height: 100vh;
      overflow: hidden;
      @media (max-width: 768px) {
        height: 70vh;
      }
      @media (max-width: 576px) {
        height: 40vh;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100vh;
        @media (max-width: 768px) {
          height: 70vh;
        }
        @media (max-width: 576px) {
          height: 40vh;
        }
      }

      .slick-prev {
        left: 70px;
        z-index: 9999 !important;
      }
      .slick-prev:before {
        content: url("../../assets//images/testonomial/prev.svg");
      }
      .slick-next {
        right: 70px;
        z-index: 9999;
      }

      @media (max-width: 576px) {
        .slick-prev {
          left: 5px;
        }
        .slick-next {
          right: 25px;
        }
      }

      .slick-next:before {
        content: url("../../assets//images/testonomial/next.svg");
      }
    }
  }

  .about {
    padding: 60px 120px;
    background-color: #020002;

    ol {
      list-style: disc;
      padding-right: 16px;
      li {
        font-size: 16px;
        padding: 10px 0px;
        color: rgb(255, 255, 255, 0.6);
        font-weight: 300;
      }
    }
    @media (max-width: 1200px) {
      padding: 60px 60px;
    }
    @media (max-width: 576px) {
      padding: 30px 30px;
    }
    .imgss {
      width: 600px;
      padding: 40px;
      object-fit: cover;
      @media (max-width: 1025px) {
        height: 500px;
      }
      @media (max-width: 768px) {
        height: 400px;
      }
    }
    h5 {
      color: white;
      font-size: 30px;
      font-style: normal;
      font-weight: 300;
      text-transform: uppercase;
      @media (max-width: 576px) {
        font-size: 22px;
      }
    }
    p {
      padding-top: 12px;
      color: white;
      font-size: 16px;
    }
    .aboutbody {
      padding: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media (max-width: 768px) {
        padding: 30px;
      }
      @media (max-width: 576px) {
        margin-top: 20px;
        padding: 0px;
      }
      .cards {
        border-left: 1px solid #2e2e2e;
        border-right: 1px solid #2e2e2e;
        width: 601px;
        color: var(--Grey-font, #606060);
        text-align: center;
        font-family: Ubuntu;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 55px;
        text-transform: uppercase;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
  .bgimg {
    height: 400px;
    @media (max-width: 768px) {
      height: 200px;
    }
    img {
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
  .mcards {
    background-color: #020002;
    padding: 60px 120px;
    .img3 {
      img {
        width: 318px;
        height: 318px;
        object-fit: cover;
      }
    }
    @media (max-width: 576px) {
      padding: 30px 30px;
    }
  }

  @keyframes downward {
    from {
      top: 0;
    }
    to {
      top: 100%;
    }
  }
}
