.privacy {
  padding-top: 180px;
  background-color: #000000;
  color: white;
  padding: 160px 60px 60px 60px;
  min-height: 100vh;
  @media (max-width: 576px) {
    padding: 160px 30px 30px 30px;
  }
  p {
    font-size: 18px;
    line-height: 25px;
    padding-bottom: 20px;
    span {
      font-size: 18px !important;
    }
  }
  ul {
    list-style: disc;
    li {
      padding-left: 20px;
      font-size: 16px;
    }
  }
}
