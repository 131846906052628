.clientjet {
  padding-top: 180px;
  background-color: #0e1810;
}
.clientjet .cjheading h2 {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 65.217px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.clientjet .cjbody {
  padding: 60px 180px;
}
@media (max-width: 1400px) {
  .clientjet .cjbody {
    padding: 60px 60px;
  }
}
@media (max-width: 992px) {
  .clientjet .cjbody {
    padding: 60px 30px;
  }
}
.clientjet .cjbody .dropdown {
  margin-right: 2rem;
}
.clientjet .cjbody .dropdown select {
  cursor: pointer;
  width: 279px;
  height: 50px;
  padding: 7px 20px;
  border-bottom: 1px solid white;
  background-color: #0a120b;
  color: white;
}
@media (max-width: 640px) {
  .clientjet .cjbody .dropdown select {
    width: 200px;
  }
}
.clientjet .cjbody .dropdown select option {
  margin: 7px 20px;
  font-size: 16px;
}
.clientjet .cjbody .items {
  color: white;
}
@media (max-width: 640px) {
  .clientjet .cjbody .items .cont {
    width: 100%;
  }
}
.clientjet .cjbody .items .cont .contbody {
  border: 1px solid var(--Grey-font, #606060);
}
.clientjet .cjbody .items .cont .contbody img {
  height: 250px;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.clientjet .cjbody .items .cont .contbody .info h3 {
  color: var(--White, #fff);
  font-family: Ubuntu;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.33px;
  text-transform: uppercase;
}
.clientjet .cjbody .items .cont .contbody .info p {
  color: var(--White, #fff);
  font-family: Ubuntu;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.255px;
}/*# sourceMappingURL=style.css.map */