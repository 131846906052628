.testimonial {
  background-color: black;
  padding: 60px 0px;
  overflow-x: hidden;
}
.testimonial .heading {
  padding: 0px 30px;
}
.testimonial .sildertest {
  padding: 60px 0px;
}
.testimonial .sildertest .cimg {
  overflow: hidden;
}
.testimonial .sildertest .cimg img {
  height: 150px;
  border-radius: 50%;
  width: 150px;
  -o-object-fit: cover;
     object-fit: cover;
}
.testimonial .sildertest h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.testimonial .sildertest h6 {
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  padding-bottom: 30px;
}
.testimonial .sildertest p {
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 576px) {
  .testimonial .sildertest p {
    font-size: 16px;
  }
}
.testimonial .sildertest .dec img {
  max-width: 25px;
  height: 20px;
}
.testimonial .sildertest .slick-prev {
  left: 70px;
  z-index: 1 !important;
}
@media (max-width: 576px) {
  .testimonial .sildertest .slick-next {
    top: 30%;
    right: 40px;
  }
  .testimonial .sildertest .slick-prev {
    top: 30%;
    left: 40px;
    z-index: 1 !important;
  }
}
.testimonial .sildertest .slick-prev:before {
  z-index: 1 !important;
  content: url("../../assets//images/testonomial/prev.svg");
}
.testimonial .sildertest .slick-next {
  right: 70px;
  z-index: 9999;
}
@media (max-width: 576px) {
  .testimonial .sildertest .slick-next {
    top: 30%;
    right: 40px;
  }
  .testimonial .sildertest .slick-prev {
    top: 30%;
    left: 40px;
  }
}
.testimonial .sildertest .slick-next:before {
  content: url("../../assets//images/testonomial/next.svg");
}
.testimonial .sildertest .slick-dots {
  bottom: -70px;
}
.testimonial .sildertest .slick-dots li {
  width: 45px;
  height: 3px;
}
.testimonial .sildertest .slick-dots .slick-active {
  width: 80px;
  background-color: #7df08e;
}
@media (max-width: 576px) {
  .testimonial .sildertest .slick-dots li {
    width: auto;
    height: auto;
  }
  .testimonial .sildertest .slick-dots .slick-active {
    width: auto;
    background-color: #7df08e;
  }
}
.testimonial .sildertest .slick-dots li button {
  background-color: white;
  height: 3px;
  width: 40px;
  padding: 0px;
}
.testimonial .sildertest .slick-dots li button:before {
  content: "";
  height: 2px !important;
  width: 40px !important;
}
.testimonial .sildertest .slick-dots li.slick-active button {
  height: 2px !important;
}
.testimonial .sildertest .slick-dots li.slick-active button:before {
  background-color: #7df08e;
  height: 3px !important;
  width: 80px;
}/*# sourceMappingURL=style.css.map */