footer {
  background-color: #111;
  color: white;
  .cont {
    padding: 70px 120px;
    cursor: pointer;
    @media (max-width: 1200px) {
      padding: 60px 60px;
    }
    @media (max-width: 576px) {
      padding: 30px 30px;
    }
    h2 {
      color: #fff;
      font-size: 25.385px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 2.538px;
      text-transform: uppercase;
    }
    ul {
      li {
        padding-bottom: 20px;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        &:hover {
          color: #d9d8d8;
        }
      }
    }
  }
  .foot {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 30px;
    text-align: center;
    background-color: #7df08e;
    color: black;
  }
}
