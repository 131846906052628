.brand {
  background-color: black;
  padding: 60px 0;
  @media (max-width: 576px) {
    padding: 30px 0px;
  }
  .heading {
    padding: 0px 30px;
  }
  img {
    object-fit: contain;
    height: 100px;
    width: 240px;
    margin: 0px 30px;

    @media (max-width: 576px) {
      margin: 0px 15px;
      height: 50px;
      width: 150px;
    }
  }

  .brandss {
    padding: 0px 40px !important;
    img {
      // border: 5px dashed white;
      // padding: 20px;
      object-fit: contain;
    }
  }

  .sbody {
    margin: 0px 90px;
    // border: 2px solid white;
    padding: 60px 0px 0px 0px;
    font-size: 1.125rem;

    .sliders {
      .slick-prev:before {
        content: url("../../assets//images/testonomial/prev.svg");
      }
      .slick-next:before {
        content: url("../../assets//images/testonomial/next.svg");
      }
      .slick-prev {
        // top: 100%;
        background-color: black;
        border-radius: 50%;
        left: -60px;
        width: 50px;
        height: 50px;
        z-index: 1 !important;
      }
      .slick-next {
        // top: 100%;
        background-color: black;
        border-radius: 50%;
        right: -60px;
        width: 50px;
        height: 50px;
        z-index: 1 !important;
      }
    }
    .contbtn1 {
      top: 50%;
      position: absolute;
      left: -90px;
      img {
        width: 42px;
        height: 42px;
        filter: brightness(0.7);
        // @media (max-width: 576px) {
        //   width: 25px;
        //   height: 25px;
        // }
      }
    }
    .contbtn2 {
      top: 50%;
      position: absolute;
      right: -90px;
      img {
        width: 42px;
        height: 42px;
        filter: brightness(0.7);

        // @media (max-width: 576px) {
        //   width: 25px;
        //   height: 25px;
        // }
      }
    }
    .scroller {
      max-width: 100%;
      position: relative;
    }

    .scroller__inner {
      padding-block: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    .scroller[data-animated="true"] {
      overflow: hidden;
    }

    .scroller[data-animated="true"] .scroller__inner {
      width: max-content;
      flex-wrap: nowrap;
      animation: scroll var(--_animation-duration, 40s)
        var(--_animation-direction, forwards) linear infinite;
    }

    .scroller[data-direction="right"] {
      --_animation-direction: reverse;
    }

    .scroller[data-direction="left"] {
      --_animation-direction: forwards;
    }

    .btnrev {
      --_animation-direction: reverse;
    }

    .btnnext {
      --_animation-direction: forwards;
    }

    .scroller[data-speed="slow"] {
      --_animation-duration: 420s;
    }

    .scroller[data-speed="fast"] {
      --_animation-duration: 110s;
    }

    @keyframes scroll {
      to {
        transform: translate(calc(-50% - 1rem));
      }
    }
  }

  .sbody .scroller:hover .scroller__inner {
    animation-play-state: paused;
  }
}
