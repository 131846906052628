.mainsidebar {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
  z-index: 99;
  position: fixed;
}
.mainsidebar .sidebar {
  z-index: 999;
  margin-left: auto;
  width: 700px;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 0px;
}
@media (max-width: 768px) {
  .mainsidebar .sidebar {
    width: 450px;
  }
}
@media (max-width: 576px) {
  .mainsidebar .sidebar {
    width: 100%;
  }
}
.mainsidebar .sidebar .head {
  padding: 0px 120px;
}
@media (max-width: 768px) {
  .mainsidebar .sidebar .head {
    padding: 0px 60px;
  }
}
.mainsidebar .sidebar .head h5 {
  color: var(--Black, #000);
  font-family: Ubuntu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
}
.mainsidebar .sidebar .navs ul {
  list-style: none;
  cursor: pointer;
}
.mainsidebar .sidebar .navs ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 120px;
  height: 70px;
  color: #000;
  font-family: var(--ubuntu);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  position: relative;
  align-items: center;
  z-index: 99;
}
.mainsidebar .sidebar .navs ul li:hover {
  color: white;
}
@media (max-width: 768px) {
  .mainsidebar .sidebar .navs ul li {
    padding: 0px 60px;
    font-size: 20px;
    height: 50px;
  }
}
.mainsidebar .sidebar .navs ul li::before {
  content: "";
  position: absolute;
  inset: 0;
  width: 0%;
  height: 100%;
  background-color: #000;
  z-index: -1;
  transition: width 0.3s ease;
}
.mainsidebar .sidebar .navs ul li:hover::before {
  width: 100%;
}
.mainsidebar .sidebar .social-icon {
  padding: 0px 120px;
}
@media (max-width: 576px) {
  .mainsidebar .sidebar .social-icon {
    padding: 0px 60px;
    margin-bottom: 3rem;
  }
}
.mainsidebar .sidebar .social-icon ul li {
  margin-right: 40px;
  padding: 5px;
}
.mainsidebar .sidebar .social-icon ul li:hover {
  border-radius: 50%;
  box-shadow: 0px 0px 20px rgb(219, 217, 217);
  padding: 5px;
}
@media (max-width: 768px) {
  .mainsidebar .sidebar .social-icon ul li img {
    max-width: 50px;
    height: 25px;
  }
}
@media (max-width: 576px) {
  .mainsidebar .sidebar .social-icon ul li {
    margin-right: 20px;
  }
}

@keyframes nav {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}/*# sourceMappingURL=sidebar.css.map */