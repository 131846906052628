.Compromise {
  background-color: black;
  padding: 60px 120px;
}
@media (max-width: 992px) {
  .Compromise {
    padding: 60px 60px;
  }
}
@media (max-width: 576px) {
  .Compromise {
    padding: 30px 30px;
  }
}
.Compromise .heading {
  padding: 0px 80px;
  text-align: center;
}
@media (max-width: 768px) {
  .Compromise .heading {
    padding: 0px 0px;
  }
}
.Compromise .sbody {
  padding: 60px 0px 0px 0px;
  font-size: 1.125rem;
}
.Compromise .sbody .scroller {
  max-width: 100%;
}
.Compromise .sbody .scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.Compromise .sbody .scroller[data-animated=true] {
  overflow: hidden;
}
.Compromise .sbody .scroller[data-animated=true] .scroller__inner {
  width: -moz-max-content;
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s) var(--_animation-direction, forwards) linear infinite;
}
.Compromise .sbody .scroller[data-direction=right] {
  --_animation-direction: reverse;
}
.Compromise .sbody .scroller[data-direction=left] {
  --_animation-direction: forwards;
}
.Compromise .sbody .scroller[data-speed=fast] {
  --_animation-duration: 20s;
}
.Compromise .sbody .scroller[data-speed=slow] {
  --_animation-duration: 520s;
}
@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}
.Compromise .sbody .scroller:hover .scroller__inner {
  animation-play-state: paused;
}/*# sourceMappingURL=Compromise.css.map */