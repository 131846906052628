.sellform {
  .Contactusform {
    position: fixed;
    height: 100vh;
    left: 0;
    right: 0;
    transition: 1s ease-in;
    top: 0;
    z-index: 99;
    color: white;
    width: 100%;
    overflow-y: scroll;
    background-color: #0e1810;
    &::-webkit-scrollbar {
      display: none;
    }
    .head {
      background-color: #2f5634;
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .formbody {
      h5 {
        color: #fff;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 4px;
        text-transform: uppercase;
      }
      padding: 60px 120px;
      @media (max-width: 992px) {
        padding: 60px 30px;
      }
      select {
        background-color: #0e1810;
      }
      .form-field {
        background-color: #0a120b !important;
        padding: 0px 10px;
        margin-bottom: 4px;
        border-bottom: 1px solid #fff;
        input {
          color: white;
          padding: 14px 20px;
          background-color: #0a120b;
          width: 100%;
        }
      }
      .form-fieldphone {
        border-bottom: 1px solid white;
        input {
          padding: 14px 20px;
          background-color: #0a120b;
        }
      }
      textarea {
        width: 100%;
        background-color: #0a120b;
        padding: 14px 20px;
        color: #fff;
        height: 100px;
      }
      .checkbox {
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .checkbox__input {
        position: absolute;
        width: 1.375em;
        height: 1.375em;
        opacity: 0;
        cursor: pointer;
      }

      .checkbox__input:checked + .checkbox__icon .tick {
        stroke-dashoffset: 0;
      }

      .checkbox__icon {
        width: 1.375em;
        height: 1.375em;
        flex-shrink: 0;
        overflow: visible;
      }

      .checkbox__icon .tick {
        stroke-dasharray: 20px;
        filter: blur(0px) !important;
        stroke-dashoffset: 20px;
        transition: stroke-dashoffset 0.2s ease-out;
      }
    }
    .otpbody {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      h5 {
        color: #fff;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
      p {
        padding: 20px;
        color: white;
        font-size: 20px;
      }
      input {
        margin: 50px 10px;
        border-bottom: 2px solid white;
        height: 50px;
        width: 50px !important;
        background-color: black;
        color: white;
      }
    }
    .thanks {
      padding: 30px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80%;
      flex-direction: column;
      img {
        height: 200px;
        width: 200px;
        margin-left: 30px;
      }
      h5 {
        font-size: 40px;
        color: white;
        text-transform: uppercase;
      }
      p {
        font-size: 20px;
        color: white;
      }
    }
  }
  .imguploadcont {
    .img-wrap {
      margin: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
      object-fit: cover;
      width: 200px;
      background-color: #2f5634;
      cursor: pointer;
      @media (max-width: 992px) {
        height: 150px;
        max-width: 150px;
      }
      @media (max-width: 768px) {
        height: 120px;
        max-width: 120px;
      }
      @media (max-width: 576px) {
        height: 70px;
        max-width: 70px;
      }
    }
  }
  .uplaodImages {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .imagess {
      position: relative;
      border: 1px solid white;
      margin: 10px;
      img {
        height: 200px;
        max-width: 200px;
        width: 200px;
        @media (max-width: 992px) {
          height: 150px;
          max-width: 150px;
          width: 150px;
        }
        @media (max-width: 768px) {
          height: 120px;
          max-width: 120px;
        }
        @media (max-width: 576px) {
          height: 70px;
          max-width: 70px;
        }
      }
      .removebtm {
        position: absolute;
        top: 15px;
        right: 15px;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .pdfcont {
    .headings {
      h2 {
        font-size: 30px;
        padding-bottom: 20px;
        @media (max-width: 576px) {
          font-size: 20px;
        }
      }
    }
    .pdformate {
      cursor: pointer;
      border: 2px solid white;
      width: fit-content;
      p {
        width: 130px;
        height: 26px;
        overflow: hidden;
        @media (max-width: 330px) {
          width: 100px;
        }
      }
    }
    .pdf-wrap {
      cursor: pointer;
      background-color: #2f5634;
      width: fit-content;
      padding: 10px 30px;
      color: #7df08e;
      border: 2px solid white;
    }
  }

  .tsfbefore {
    transform: translateY(100%);
    transition: 500ms ease-in;
  }
  .tsfafter {
    transition: 500ms ease-in;
    transform: translateY(0%);
  }
}
