.facts {
  background: url("../../assets/images/facts/bg.png") center/cover no-repeat
    black;
  mix-blend-mode: multiply;
  padding: 60px 140px;
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    padding: 60px 60px;
  }

  .heading {
    padding: 0px 15px;
    h2,
    p {
      text-align: left;
    }
    h2 {
      @media (max-width: 1200px) {
        font-size: 30px;
      }
      @media (max-width: 576px) {
        text-align: left;
      }
    }
    @media (max-width: 1025px) {
      margin-bottom: 22px;
    }
  }
  .basisss {
    border: 2px solid white;
  }
  .facttitle {
    top: 0;
    bottom: 0;
    left: 15px;
    z-index: 9;
    transform: rotate(270deg);
    @media (max-width: 500px) {
      left: 0;
    }
    h4 {
      color: #fff;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      background-color: #7df08e;
      padding: 15px 30px;
    }
  }
  .sides {
    @media (max-width: 1025px) {
      flex-wrap: wrap;
      justify-content: center;
    }
    .side1 {
      .swiper-pagination-bullet {
        width: 40px;
        height: 4px;
        border-radius: 0;
      }
      .swiper-pagination-bullet-active {
        background: #7df08e;
      }
      margin-top: -40px;
      margin-right: 20px;
      z-index: 2;
      width: 530px;
      height: 350px;
      @media (max-width: 1250px) {
        margin-top: 0;
        width: 450px;
        height: 310px;
      }
      @media (max-width: 1100px) {
        height: 340px;
        margin-right: 0px;
      }
      @media (max-width: 1024px) {
        height: 340px;
        width: 600px;
      }
      @media (max-width: 768px) {
        width: 600px;
      }
      @media (max-width: 670px) {
        width: 400px;
      }
      @media (max-width: 440px) {
        width: 350px;
        height: 100%;
      }
      @media (max-width: 380px) {
        width: 300px;
        height: 100%;
      }
      .slider1 {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .slick-dots {
          bottom: 20px;
        }
        .slick-dots li {
          width: 45px;
          height: 3px;
        }
        .slick-dots .slick-active {
          background-color: #7df08e;
        }

        .slick-dots li button {
          background-color: white;
          height: 3px;
          width: 40px;
          padding: 0px;
        }
        .slick-dots li button:before {
          content: "";
          height: 2px !important;
          width: 40px !important;
        }
        .slick-dots li.slick-active button {
          height: 2px !important;
        }
        .slick-dots li.slick-active button:before {
          background-color: #7df08e;
          height: 3px !important;
          width: 80px;
        }
      }
    }

    .slide2 >,
    .slick-next {
      transform: rotate(90deg);
    }
    .slide2 >,
    .slick-prev {
      transform: rotate(90deg);
    }

    .side2 {
      border: 2px solid white;
      width: 600px;
      height: 310px;
      overflow-y: hidden;

      @media (max-width: 1250px) {
        width: 600px;
        height: 310px;
      }
      @media (max-width: 1100px) {
        width: 550px;
        height: 340px;
      }

      @media (max-width: 1025px) {
        margin-top: 30px;
        width: 600px;
        height: 100%;
      }
      @media (max-width: 670px) {
        width: 400px;
        height: 100%;
      }

      @media (max-width: 400px) {
        width: 340px;
      }
      @media (max-width: 365px) {
        width: 300px;
      }
      .slider2 {
        .swiper-button-prev {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #fff;
          color: black;
          font-weight: 900;
        }
        .swiper-button-prev:after {
          font-size: 16px;
        }
        .swiper-button-next {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background-color: #fff;
          color: black;
          font-weight: 900;
        }
        .swiper-button-next:after {
          font-size: 16px;
        }
        .items {
          padding: 20px 40px;
        }
        h2 {
          color: #fff;
          font-size: 22px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-bottom: 20px;
        }
        p {
          color: white;
        }
      }
    }
  }
}
