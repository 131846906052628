.tourdetails {
  padding-top: 160px;
  color: white;
  background-color: #000;
  .heading {
    padding: 0px 120px;
    @media (max-width: 1200px) {
      padding: 0px 60px;
    }
    @media (max-width: 992px) {
      padding: 0px 30px;
    }

    h2 {
      text-align: start;
      font-size: 40px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.6px;
      text-transform: uppercase;
      @media (max-width: 576px) {
        font-size: 30px;
      }
    }
  }
  .bannners {
    padding: 60px 120px;
    @media (max-width: 1200px) {
      padding: 60px 60px;
    }
    @media (max-width: 992px) {
      padding: 60px 30px;
    }
    .gallery {
      position: relative;
      display: grid;
      gap: 10px;
      @media (max-width: 576px) {
        height: 100%;
        gap: 5px;
      }
      .obsimg {
        position: absolute;
        bottom: 40px;
        left: 30px;
        color: white;
        @media (max-width: 576px) {
          left: 15px;
          font-size: 12px;
          font-weight: 700;
        }
        @media (max-width: 450px) {
          left: 10px;
        }
      }
      .item1,
      .item2,
      .item3,
      .item4 {
        height: 100%;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
      .item5 {
        img {
          object-fit: cover;
          height: 100%;
        }
      }
      .item1 {
        grid-area: item1;
      }
      .item2 {
        grid-area: item2;
      }
      .item3 {
        grid-area: item3;
      }
      .item4 {
        grid-area: item4;
      }
      .item5 {
        grid-area: item5;
      }
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(4, 10vw);
      @media (max-width: 992px) {
        grid-template-rows: repeat(4, 14vw);
      }
      @media (max-width: 820px) {
        grid-template-rows: repeat(4, 16vw);
      }
      @media (max-width: 576px) {
        grid-template-rows: repeat(4, 20vw);
      }
      @media (max-width: 340px) {
        grid-template-rows: repeat(4, 24vw);
      }
      grid-template-areas:
        "item2 item5 item5 item5"
        "item3 item5 item5 item5"
        "item4 item5 item5 item5"
        "item1 item5 item5 item5";
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .textsection {
      h2 {
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 12px;
      }
      p {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        padding-bottom: 16px;
      }
      ul,
      ol {
        list-style: disc;
        padding-left: 2rem;
      }
    }
  }
  .inficon {
    background-color: #0e1810;
    padding: 60px 30px;

    @media (max-width: 992px) {
      padding: 30px 30px;
    }

    .infocards {
      margin: 20px 20px;
      border-radius: 50%;
      width: 156.471px;
      height: 156.471px;
      overflow: hidden;
      @media (max-width: 576px) {
        height: 100px;
        width: 100px;
      }
      img {
        width: 100%;
        height: 100%;
      }
      .cont {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #2f5634;
        display: none;
        h6 {
          text-transform: capitalize;
          font-size: 18px;
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }
      }
      &:hover img {
        display: none;
      }
      &:hover .cont {
        display: flex;
      }
    }
  }
  .bannnerstwo {
    padding: 60px 120px;
    @media (max-width: 1200px) {
      padding: 60px 60px;
    }
    @media (max-width: 992px) {
      padding: 60px 30px;
    }
    .gallery {
      display: grid;
      gap: 10px;
      @media (max-width: 576px) {
        gap: 5px;
      }
      .item1 {
        grid-area: item1;
      }
      .item2 {
        grid-area: item2;
      }
      .item3 {
        grid-area: item3;
      }
      .item4 {
        grid-area: item4;
      }
      .item5 {
        grid-area: item5;
      }
      .item6 {
        height: 150px;
        grid-area: item6;
      }

      .item2,
      .item5 {
        img {
          // height: 150px;
          @media (max-width: 576px) {
            height: 100px;
          }
        }
      }
      .item4,
      .item1,
      .item3 {
        img {
          // height: 300px;
          @media (max-width: 576px) {
            height: 200px;
          }
        }
      }
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(5, 10w);
      grid-template-areas:
        "item1 item2"
        "item1 item4"
        "item3 item4"
        "item3 item5"
        "item6 item6";
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .textsection {
      h2 {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 12px;
      }
      p {
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      ul,
      ol {
        padding-top: 20px;
        list-style: disc;
        padding-left: 1rem;
        li {
          padding-bottom: 1rem;
        }
      }
    }
  }
}
