.tourenquiry {
  .Contactusform {
    position: fixed;
    height: 100vh;
    left: 0;
    right: 0;
    transition: 1s ease-in;
    top: 0;
    z-index: 99;
    color: white;
    width: 100%;
    overflow-y: scroll;
    background-color: #0e1810;

    &::-webkit-scrollbar {
      display: none;
    }
    .head {
      background-color: #2f5634;
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .formbody {
      h5 {
        color: #fff;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
      padding: 60px 120px;
      @media (max-width: 992px) {
        padding: 60px 30px;
      }
      select {
        background-color: #0a120b;
      }
      .form-field {
        background-color: #0a120b !important;
        padding: 0px 10px;
        margin-bottom: 4px;
        border-bottom: 1px solid #fff;
        input {
          color: white;
          padding: 14px 20px;
          background-color: #0a120b;
          width: 100%;
        }
      }
      .form-fieldphone {
        border-bottom: 1px solid white;
        input {
          padding: 14px 20px;
          background-color: #0a120b;
        }
      }
      textarea {
        width: 100%;
        background-color: #0a120b;
        padding: 14px 20px;
        color: #fff;
        height: 100px;
      }

      select {
        padding: 16px 0px;
      }
      option {
        font-size: 16px;
      }
    }
    .otpbody {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      h5 {
        color: #fff;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        letter-spacing: 3px;
        text-transform: uppercase;
      }
      p {
        padding: 20px;
        color: white;
        font-size: 20px;
      }
      input {
        margin: 50px 10px;
        border-bottom: 2px solid white;
        height: 50px;
        width: 50px !important;
        background-color: black;
        color: white;
      }
    }
    .thanks {
      padding: 30px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80%;
      flex-direction: column;
      img {
        height: 200px;
        width: 200px;
        margin-left: 30px;
      }
      h5 {
        font-size: 40px;
        color: white;
        text-transform: uppercase;
      }
      p {
        font-size: 20px;
        color: white;
      }
    }
  }

  .tsfbefore {
    transform: translateY(200%);
    transition: 500ms ease-in;
  }
  .tsfafter {
    transition: 500ms ease-in;
    transform: translateY(0%);
  }
}
