.jetdetails .banners {
  height: 100vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: black;
}
.jetdetails .banners .bg img {
  width: 100%;
  height: 100vh;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 48% 51%;
     object-position: 48% 51%;
}
.jetdetails .banners .bgjetg700 img {
  width: 100%;
  height: 100vh;
  filter: brightness(0.8);
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 48% 100%;
     object-position: 48% 100%;
}
@media (max-width: 992px) {
  .jetdetails .banners .bgjetg700 img {
    -o-object-position: 70% 100%;
       object-position: 70% 100%;
  }
}
@media (max-width: 768px) {
  .jetdetails .banners .bgjetg700 img {
    -o-object-position: 80% 100%;
       object-position: 80% 100%;
  }
}
@media (max-width: 576px) {
  .jetdetails .banners .bgjetg700 img {
    -o-object-position: 90% 51%;
       object-position: 90% 51%;
  }
}
.jetdetails .banners .text-section {
  margin-bottom: 75px;
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  justify-content: end;
  flex-direction: column;
}
.jetdetails .banners .text-section .texthead {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
}
@media (max-width: 400px) {
  .jetdetails .banners .text-section .texthead {
    display: block;
  }
}
.jetdetails .banners .text-section .texthead h2 {
  color: #fff;
  font-family: Ubuntu;
  font-size: 65.217px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .jetdetails .banners .text-section .texthead h2 {
    font-size: 40px;
  }
}
.jetdetails .banners .text-section .texthead .span {
  padding-right: 10px;
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 65.217px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .jetdetails .banners .text-section .texthead .span {
    font-size: 40px;
  }
}
.jetdetails .banners .text-section h3 {
  color: #fff;
  text-align: center;
  font-family: Ubuntu;
  font-size: 65.217px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .jetdetails .banners .text-section h3 {
    font-size: 50px;
  }
}
.jetdetails .banners .scroll-down {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 3px;
  height: 100px;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.3019607843);
}
.jetdetails .banners .scroll-down .point {
  height: 30px;
  position: relative;
  width: 3px;
  background-color: white;
  animation: downward 1s linear infinite;
}
.jetdetails .best {
  background-color: #020002;
  padding: 60px 120px;
}
@media (max-width: 1200px) {
  .jetdetails .best {
    padding: 60px 60px;
  }
}
.jetdetails .best .cont .contbody {
  padding: 60px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media (max-width: 768px) {
  .jetdetails .best .cont .contbody {
    padding: 0px 0px 60px 0px;
  }
}
.jetdetails .best .cont .contbody .imgcont {
  width: 223px;
  height: 223px;
  background-color: #0e1810;
  display: grid;
  place-items: center;
  border-radius: 50%;
}
@media (max-width: 1200px) {
  .jetdetails .best .cont .contbody .imgcont {
    width: 180px;
    height: 180px;
  }
}
.jetdetails .best .cont .contbody p {
  padding-top: 24px;
  color: white;
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  padding: 0px 20px;
}
@media (max-width: 1200px) {
  .jetdetails .best .cont .contbody p {
    font-size: 18px;
    margin-top: 20px;
  }
}
.jetdetails .jetslider {
  width: 100%;
  overflow: hidden;
  background-color: black;
}
.jetdetails .jetslider .sldiers {
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 768px) {
  .jetdetails .jetslider .sldiers {
    height: 70vh;
  }
}
@media (max-width: 576px) {
  .jetdetails .jetslider .sldiers {
    height: 40vh;
  }
}
.jetdetails .jetslider .sldiers img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100vh;
}
@media (max-width: 768px) {
  .jetdetails .jetslider .sldiers img {
    height: 70vh;
  }
}
@media (max-width: 576px) {
  .jetdetails .jetslider .sldiers img {
    height: 40vh;
  }
}
.jetdetails .jetslider .sldiers .slick-prev {
  left: 70px;
  z-index: 9999 !important;
}
.jetdetails .jetslider .sldiers .slick-prev:before {
  content: url("../../assets//images/testonomial/prev.svg");
}
.jetdetails .jetslider .sldiers .slick-next {
  right: 70px;
  z-index: 9999;
}
@media (max-width: 576px) {
  .jetdetails .jetslider .sldiers .slick-prev {
    left: 5px;
  }
  .jetdetails .jetslider .sldiers .slick-next {
    right: 25px;
  }
}
.jetdetails .jetslider .sldiers .slick-next:before {
  content: url("../../assets//images/testonomial/next.svg");
}
.jetdetails .about {
  padding: 60px 120px;
  background-color: #020002;
}
.jetdetails .about ol {
  list-style: disc;
  padding-right: 16px;
}
.jetdetails .about ol li {
  font-size: 16px;
  padding: 10px 0px;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
}
@media (max-width: 1200px) {
  .jetdetails .about {
    padding: 60px 60px;
  }
}
@media (max-width: 576px) {
  .jetdetails .about {
    padding: 30px 30px;
  }
}
.jetdetails .about .imgss {
  width: 600px;
  padding: 40px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 1025px) {
  .jetdetails .about .imgss {
    height: 500px;
  }
}
@media (max-width: 768px) {
  .jetdetails .about .imgss {
    height: 400px;
  }
}
.jetdetails .about h5 {
  color: white;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .jetdetails .about h5 {
    font-size: 22px;
  }
}
.jetdetails .about p {
  padding-top: 12px;
  color: white;
  font-size: 16px;
}
.jetdetails .about .aboutbody {
  padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .jetdetails .about .aboutbody {
    padding: 30px;
  }
}
@media (max-width: 576px) {
  .jetdetails .about .aboutbody {
    margin-top: 20px;
    padding: 0px;
  }
}
.jetdetails .about .aboutbody .cards {
  border-left: 1px solid #2e2e2e;
  border-right: 1px solid #2e2e2e;
  width: 601px;
  color: var(--Grey-font, #606060);
  text-align: center;
  font-family: Ubuntu;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: 55px;
  text-transform: uppercase;
}
@media (max-width: 768px) {
  .jetdetails .about .aboutbody .cards {
    width: 100%;
  }
}
.jetdetails .bgimg {
  height: 400px;
}
@media (max-width: 768px) {
  .jetdetails .bgimg {
    height: 200px;
  }
}
.jetdetails .bgimg img {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
}
.jetdetails .mcards {
  background-color: #020002;
  padding: 60px 120px;
}
.jetdetails .mcards .img3 img {
  width: 318px;
  height: 318px;
  -o-object-fit: cover;
     object-fit: cover;
}
@media (max-width: 576px) {
  .jetdetails .mcards {
    padding: 30px 30px;
  }
}
@keyframes downward {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}/*# sourceMappingURL=style.css.map */