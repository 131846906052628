.Dealwith {
  height: 100%;
  position: relative;
  width: 100%;
  background-color: black;
  @media (max-width: 1025px) {
    padding: 0px 60px;
  }
  // padding: 60px 0px;
  @media (max-width: 576px) {
    padding: 30px 0px;
  }

  @media (max-width: 1025px) {
    overflow: auto;
    height: 100%;
  }
  .bgvideo {
    width: 100%;
    object-fit: cover;
    @media (max-width: 1025px) {
      height: 130vh;
    }
    @media (max-width: 640px) {
      height: 230vh;
    }
    @media (max-width: 340px) {
      height: 220vh;
    }
    video {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
  .body-section {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .heading {
      h2 {
        padding: 60px 0px;
        @media (max-width: 768px) {
          padding: 0px 0px 60px 0px;
        }
      }
    }
    .dealcardbody {
      cursor: pointer;
      padding: 0px 200px;
      @media (max-width: 1400px) {
        padding: 0px 0px;
      }
      @media (max-width: 768px) {
        padding: 0px 30px;
      }
      .dealcardcont {
        width: 100%;
        padding: 0px 20px;
        height: 500px;
        position: relative;
        @media (max-width: 1500px) {
          height: 430px;
        }
        .name {
          position: absolute;
          bottom: 20px;
          left: 0;
          right: 0;
          h3 {
            color: white;
            text-align: center;
            font-family: Ubuntu;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
          }
        }
        .dealcard {
          height: 100%;
          background-color: white;
          overflow: hidden;
          .hoverimg {
            object-fit: cover;
            height: 100%;
            width: 100%;
            &:hover {
              transform: scale(1.3);
            }
          }
          .hovervideo {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
