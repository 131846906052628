* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
  outline: none;
  transition: 0.2s;
  font-family: var(--Ubuntu);
}
:root {
  --white: #ffffff;
  --black: black;
  --gray: rgb(0, 0, 0, 0.5);
  --blue: #1492e6;
  --Bilbo: Bilbo;
  --Ubuntu: Ubuntu;
  --Bangers: Bangers;
  --Green: #7df08e;
  --green-dark: #2f5634;
  --green-darkest: #0e1810;
  --Dark-grey-footer: #111;
  --Dark-grey: #2e2e2e;
  --Grey-font: #606060;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  margin-bottom: 0em;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
ul li {
  margin-left: 0em;
}

.text-white {
  color: var(--white);
}
.text-black {
  color: var(--black);
}
.text-gray {
  color: var(--Grey-font);
}
.bg-black {
  background-color: var(--black);
}
.text-green {
  color: var(--green);
}
.bg-green-dark {
  background-color: var(--green-dark);
}
.bg-green-darkest {
  background-color: var(--green-darkest);
}

/* font-family: 'Bilbo Swash Caps', cursive; */

/* font-family: 'Ubuntu', sans-serif; */

/* font-family: "Bangers , cursive*/

.heading h2 {
  color: #fff;
  text-align: center;
  font-family: var(--Ubuntu);
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.heading p {
  color: #fff;
  text-align: center;
  font-family: var(--Ubuntu);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  text-transform: uppercase;
}

@media (max-width: 576px) {
  .heading h2 {
    font-size: 28px;
  }
  .heading p {
    font-size: 15px;
  }
}

.button1 button {
  color: #fff;
  text-align: center;
  font-size: 29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  padding: 10px 40px;
  border-radius: 20px;
  border: 1px solid #fff;
  background: linear-gradient(180deg, #b97208 0%, #ffd800 100%);
  box-shadow: 10.24px 10.24px 19.2px 0px rgba(20, 21, 29, 0.6),
    -7.68px -7.68px 19.2px 0px rgba(48, 50, 64, 0.6);
}
@media (max-width: 576px) {
  .button1 button {
    font-size: 20px;
    padding: 10px 30px;
  }
}
.button1 button:hover {
  background-color: #2f5634;
}
.errormessage {
  color: red;
  padding-left: 20px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #00000090;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #0a120b;
}

/* loder */

.loader {
  --dim: 3rem;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
}

.loader .circle {
  --color: #ffffff;
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.slick-prev,
.slick-next {
  z-index: auto !important;
  cursor: pointer;
}

select {
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right #ddd;
}
