.aviation {
  .banner {
    height: 70vh;
    position: relative;
    width: 100%;
    overflow: hidden;
    background-color: black;
    .imgb {
      width: 100%;
      height: 100vh;
      @media (max-width: 576px) {
        height: 60vh;
      }
    }
    @media (max-width: 576px) {
      height: 60vh;
    }
    .text-section {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0px 30px;

      h1 {
        margin-top: 50px;
        color: var(--White, #fff);
        font-family: Bangers;
        font-size: 121.53px;
        font-style: normal;
        font-weight: 400;
        text-align: center;
        line-height: 112px;
        text-transform: uppercase;
        @media (max-width: 992px) {
          font-size: 90px;
        }
        @media (max-width: 768px) {
          font-size: 70px;
        }
        @media (max-width: 576px) {
          font-size: 40px;
          line-height: 30px;
          line-height: 60px;
        }
      }
      h3 {
        color: var(--Green, #7df08e);
        text-align: center;
        text-shadow: 4px -2px 3px rgba(0, 0, 0, 0.3);
        font-family: Bilbo Swash Caps;
        font-size: 110.537px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: -50px;
        @media (max-width: 992px) {
          font-size: 80px;
        }
        @media (max-width: 768px) {
          font-size: 60px;
        }
        @media (max-width: 576px) {
          font-size: 40px;
          margin-top: 0px;
        }
      }
    }
  }
  .about {
    background-color: #0e1810;
    .aboutbody {
      padding: 15px 120px;
      @media (max-width: 1200px) {
        padding: 30px 60px 0px 60px;
      }
      @media (max-width: 576px) {
        padding: 30px 30px 0px 30px;
      }
      .text-part {
        @media (max-width: 768px) {
          // text-align: center;
        }
        ul {
          list-style: none;
          color: #fff;
          font-size: 30px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
          padding-bottom: 14px;
          @media (max-width: 768px) {
            list-style: none;
          }
        }
        padding: 30px;
        h3 {
          color: #fff;
          font-size: 30px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          text-transform: uppercase;
          padding-bottom: 14px;
        }
        p {
          color: #fff;
          font-size: 17px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          line-height: 30px;
        }
      }

      .img-parts {
        display: flex;
        justify-content: center;
        padding: 40px;
        @media (max-width: 768px) {
          padding: 0;
        }
        img {
          width: 500px;
          height: 500px;
          @media (max-width: 1500px) {
            max-width: 500px;
            height: 500px;
          }
          @media (max-width: 1200px) {
            max-width: 400px;
            height: 400px;
          }
          @media (max-width: 992px) {
            max-width: 300px;
            height: 300px;
          }
        }
      }
    }
  }
  .req {
    height: 272px;
    background-image: url("../../assets/images/aviation/bg2.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    h3 {
      font-size: 40px;
      color: white;
      @media (max-width: 992px) {
        font-size: 26px;
      }
    }
  }
  .cif {
    padding: 60px 100px;
    background-color: #0e1810;
    color: white;
    @media (max-width: 1200px) {
      padding: 60px 60px;
    }
    @media (max-width: 576px) {
      padding: 30px 60px;
    }
    .boldheading {
      padding: 20px 0px;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      @media (max-width: 576px) {
        font-size: 18px;
      }
    }
    ul {
      list-style: disc;
      padding-left: 26px;
      li {
        font-size: 17px;
        font-weight: 300;
        line-height: 30px;
      }
    }
    .mainul {
      .cr {
        border: 1px solid #7df08e;
        border-radius: 50%;
        height: 50px;
        min-width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        margin-right: 15px;
        @media (max-width: 992px) {
          height: 50px;
          min-width: 50px;
        }
        @media (max-width: 576px) {
          height: 50px;
          min-width: 50px;
        }
      }
      p {
        // padding: 40px 0px;
        font-size: 22px;
        @media (max-width: 992px) {
          font-size: 18px;
          padding: 0px 0px;
        }
        @media (max-width: 576px) {
          font-size: 16px;
          padding: 0px 0px;
        }
      }
      ol {
        list-style: decimal;
        li {
          font-size: 22px;
          padding-bottom: 16px;
          @media (max-width: 576px) {
            font-size: 18px;
          }
          &::marker {
            font-size: 1.5rem;
            color: #7df08e;
          }
          ul {
            padding: 16px;

            li {
              padding: 0;
              font-size: 17px;
              &::marker {
                font-size: 1rem;
                color: #7df08e;
              }
            }
          }
        }
      }
    }
  }
  .fob {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 321px;
    background-color: #0a120b;

    .heading {
      h2 {
        @media (max-width: 992px) {
          font-size: 36px;
        }
        @media (max-width: 576px) {
          font-size: 24px;
        }
      }
    }
  }
}
