.aviation .banner {
  height: 70vh;
  position: relative;
  width: 100%;
  overflow: hidden;
  background-color: black;
}
.aviation .banner .imgb {
  width: 100%;
  height: 100vh;
}
@media (max-width: 576px) {
  .aviation .banner .imgb {
    height: 60vh;
  }
}
@media (max-width: 576px) {
  .aviation .banner {
    height: 60vh;
  }
}
.aviation .banner .text-section {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 30px;
}
.aviation .banner .text-section h1 {
  margin-top: 50px;
  color: var(--White, #fff);
  font-family: Bangers;
  font-size: 121.53px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  line-height: 112px;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .aviation .banner .text-section h1 {
    font-size: 90px;
  }
}
@media (max-width: 768px) {
  .aviation .banner .text-section h1 {
    font-size: 70px;
  }
}
@media (max-width: 576px) {
  .aviation .banner .text-section h1 {
    font-size: 40px;
    line-height: 30px;
    line-height: 60px;
  }
}
.aviation .banner .text-section h3 {
  color: var(--Green, #7df08e);
  text-align: center;
  text-shadow: 4px -2px 3px rgba(0, 0, 0, 0.3);
  font-family: Bilbo Swash Caps;
  font-size: 110.537px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -50px;
}
@media (max-width: 992px) {
  .aviation .banner .text-section h3 {
    font-size: 80px;
  }
}
@media (max-width: 768px) {
  .aviation .banner .text-section h3 {
    font-size: 60px;
  }
}
@media (max-width: 576px) {
  .aviation .banner .text-section h3 {
    font-size: 40px;
    margin-top: 0px;
  }
}
.aviation .about {
  background-color: #0e1810;
}
.aviation .about .aboutbody {
  padding: 15px 120px;
}
@media (max-width: 1200px) {
  .aviation .about .aboutbody {
    padding: 30px 60px 0px 60px;
  }
}
@media (max-width: 576px) {
  .aviation .about .aboutbody {
    padding: 30px 30px 0px 30px;
  }
}
.aviation .about .aboutbody .text-part {
  padding: 30px;
}
.aviation .about .aboutbody .text-part ul {
  list-style: none;
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 14px;
}
@media (max-width: 768px) {
  .aviation .about .aboutbody .text-part ul {
    list-style: none;
  }
}
.aviation .about .aboutbody .text-part h3 {
  color: #fff;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 14px;
}
.aviation .about .aboutbody .text-part p {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  line-height: 30px;
}
.aviation .about .aboutbody .img-parts {
  display: flex;
  justify-content: center;
  padding: 40px;
}
@media (max-width: 768px) {
  .aviation .about .aboutbody .img-parts {
    padding: 0;
  }
}
.aviation .about .aboutbody .img-parts img {
  width: 500px;
  height: 500px;
}
@media (max-width: 1500px) {
  .aviation .about .aboutbody .img-parts img {
    max-width: 500px;
    height: 500px;
  }
}
@media (max-width: 1200px) {
  .aviation .about .aboutbody .img-parts img {
    max-width: 400px;
    height: 400px;
  }
}
@media (max-width: 992px) {
  .aviation .about .aboutbody .img-parts img {
    max-width: 300px;
    height: 300px;
  }
}
.aviation .req {
  height: 272px;
  background-image: url("../../assets/images/aviation/bg2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.aviation .req h3 {
  font-size: 40px;
  color: white;
}
@media (max-width: 992px) {
  .aviation .req h3 {
    font-size: 26px;
  }
}
.aviation .cif {
  padding: 60px 100px;
  background-color: #0e1810;
  color: white;
}
@media (max-width: 1200px) {
  .aviation .cif {
    padding: 60px 60px;
  }
}
@media (max-width: 576px) {
  .aviation .cif {
    padding: 30px 60px;
  }
}
.aviation .cif .boldheading {
  padding: 20px 0px;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
@media (max-width: 576px) {
  .aviation .cif .boldheading {
    font-size: 18px;
  }
}
.aviation .cif ul {
  list-style: disc;
  padding-left: 26px;
}
.aviation .cif ul li {
  font-size: 17px;
  font-weight: 300;
  line-height: 30px;
}
.aviation .cif .mainul .cr {
  border: 1px solid #7df08e;
  border-radius: 50%;
  height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-right: 15px;
}
@media (max-width: 992px) {
  .aviation .cif .mainul .cr {
    height: 50px;
    min-width: 50px;
  }
}
@media (max-width: 576px) {
  .aviation .cif .mainul .cr {
    height: 50px;
    min-width: 50px;
  }
}
.aviation .cif .mainul p {
  font-size: 22px;
}
@media (max-width: 992px) {
  .aviation .cif .mainul p {
    font-size: 18px;
    padding: 0px 0px;
  }
}
@media (max-width: 576px) {
  .aviation .cif .mainul p {
    font-size: 16px;
    padding: 0px 0px;
  }
}
.aviation .cif .mainul ol {
  list-style: decimal;
}
.aviation .cif .mainul ol li {
  font-size: 22px;
  padding-bottom: 16px;
}
@media (max-width: 576px) {
  .aviation .cif .mainul ol li {
    font-size: 18px;
  }
}
.aviation .cif .mainul ol li::marker {
  font-size: 1.5rem;
  color: #7df08e;
}
.aviation .cif .mainul ol li ul {
  padding: 16px;
}
.aviation .cif .mainul ol li ul li {
  padding: 0;
  font-size: 17px;
}
.aviation .cif .mainul ol li ul li::marker {
  font-size: 1rem;
  color: #7df08e;
}
.aviation .fob {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 321px;
  background-color: #0a120b;
}
@media (max-width: 992px) {
  .aviation .fob .heading h2 {
    font-size: 36px;
  }
}
@media (max-width: 576px) {
  .aviation .fob .heading h2 {
    font-size: 24px;
  }
}/*# sourceMappingURL=style.css.map */