footer {
  background-color: #111;
  color: white;
}
footer .cont {
  padding: 70px 120px;
  cursor: pointer;
}
@media (max-width: 1200px) {
  footer .cont {
    padding: 60px 60px;
  }
}
@media (max-width: 576px) {
  footer .cont {
    padding: 30px 30px;
  }
}
footer .cont h2 {
  color: #fff;
  font-size: 25.385px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.538px;
  text-transform: uppercase;
}
footer .cont ul li {
  padding-bottom: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
footer .cont ul li:hover {
  color: #d9d8d8;
}
footer .foot {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 30px;
  text-align: center;
  background-color: #7df08e;
  color: black;
}/*# sourceMappingURL=style.css.map */