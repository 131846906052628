.unlocking_deatils {
  // min-height: 100vh;
  width: 100%;
  background-color: black;
  background-image: url("../../assets/images/img/getbg.png");
  background-position: center;
  background-size: cover;
  padding-top: 140px;
  color: white;
  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 0px 200px;
    @media (max-width: 1400px) {
      padding: 0px 100px;
    }
    @media (max-width: 992px) {
      padding: 0px 60px;
    }
    @media (max-width: 768px) {
      padding: 0px 30px;
    }
    .pdfcont {
      width: 402px;
      height: 75px;
      border-radius: 11px;
      border: 4.7px solid rgb(255, 255, 255, 30%);
      align-items: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 28px;
      margin-bottom: 20px;
      margin-top: 30px;
      cursor: pointer;
      @media (max-width: 576px) {
        width: 100%;
        padding: 0px 10px;
        p {
          font-size: 14px;
        }
      }
    }
    .mainheading {
      padding-top: 80px;
      font-size: 30px;
      @media (max-width: 576px) {
        padding-top: 40px;
      }
    }
    .lines {
      height: 2px;
      width: 148px;
      background-color: white;
      opacity: 30%;
      border-radius: 30px;
      margin: 20px 25px;
    }
    p {
      font-size: 17px;
      font-weight: 300;
    }
  }
  .imgcont {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 25px;
    img {
      width: 25%;
      object-fit: cover;
      height: 202px;
      @media (max-width: 1400px) {
        height: 202px;
      }
      @media (max-width: 992px) {
        width: 50%;
      }
      @media (max-width: 576px) {
        width: 100%;
        height: 150px;
        padding: 0px 30px;
      }
    }
  }
}
