.tourdetails {
  padding-top: 160px;
  color: white;
  background-color: #000;
}
.tourdetails .heading {
  padding: 0px 120px;
}
@media (max-width: 1200px) {
  .tourdetails .heading {
    padding: 0px 60px;
  }
}
@media (max-width: 992px) {
  .tourdetails .heading {
    padding: 0px 30px;
  }
}
.tourdetails .heading h2 {
  text-align: start;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.6px;
  text-transform: uppercase;
}
@media (max-width: 576px) {
  .tourdetails .heading h2 {
    font-size: 30px;
  }
}
.tourdetails .bannners {
  padding: 60px 120px;
}
@media (max-width: 1200px) {
  .tourdetails .bannners {
    padding: 60px 60px;
  }
}
@media (max-width: 992px) {
  .tourdetails .bannners {
    padding: 60px 30px;
  }
}
.tourdetails .bannners .gallery {
  position: relative;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 10vw);
  grid-template-areas: "item2 item5 item5 item5" "item3 item5 item5 item5" "item4 item5 item5 item5" "item1 item5 item5 item5";
}
@media (max-width: 576px) {
  .tourdetails .bannners .gallery {
    height: 100%;
    gap: 5px;
  }
}
.tourdetails .bannners .gallery .obsimg {
  position: absolute;
  bottom: 40px;
  left: 30px;
  color: white;
}
@media (max-width: 576px) {
  .tourdetails .bannners .gallery .obsimg {
    left: 15px;
    font-size: 12px;
    font-weight: 700;
  }
}
@media (max-width: 450px) {
  .tourdetails .bannners .gallery .obsimg {
    left: 10px;
  }
}
.tourdetails .bannners .gallery .item1,
.tourdetails .bannners .gallery .item2,
.tourdetails .bannners .gallery .item3,
.tourdetails .bannners .gallery .item4 {
  height: 100%;
}
.tourdetails .bannners .gallery .item1 img,
.tourdetails .bannners .gallery .item2 img,
.tourdetails .bannners .gallery .item3 img,
.tourdetails .bannners .gallery .item4 img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.tourdetails .bannners .gallery .item5 img {
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
}
.tourdetails .bannners .gallery .item1 {
  grid-area: item1;
}
.tourdetails .bannners .gallery .item2 {
  grid-area: item2;
}
.tourdetails .bannners .gallery .item3 {
  grid-area: item3;
}
.tourdetails .bannners .gallery .item4 {
  grid-area: item4;
}
.tourdetails .bannners .gallery .item5 {
  grid-area: item5;
}
@media (max-width: 992px) {
  .tourdetails .bannners .gallery {
    grid-template-rows: repeat(4, 14vw);
  }
}
@media (max-width: 820px) {
  .tourdetails .bannners .gallery {
    grid-template-rows: repeat(4, 16vw);
  }
}
@media (max-width: 576px) {
  .tourdetails .bannners .gallery {
    grid-template-rows: repeat(4, 20vw);
  }
}
@media (max-width: 340px) {
  .tourdetails .bannners .gallery {
    grid-template-rows: repeat(4, 24vw);
  }
}
.tourdetails .bannners .gallery img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.tourdetails .bannners .textsection h2 {
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 12px;
}
.tourdetails .bannners .textsection p {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 16px;
}
.tourdetails .bannners .textsection ul,
.tourdetails .bannners .textsection ol {
  list-style: disc;
  padding-left: 2rem;
}
.tourdetails .inficon {
  background-color: #0e1810;
  padding: 60px 30px;
}
@media (max-width: 992px) {
  .tourdetails .inficon {
    padding: 30px 30px;
  }
}
.tourdetails .inficon .infocards {
  margin: 20px 20px;
  border-radius: 50%;
  width: 156.471px;
  height: 156.471px;
  overflow: hidden;
}
@media (max-width: 576px) {
  .tourdetails .inficon .infocards {
    height: 100px;
    width: 100px;
  }
}
.tourdetails .inficon .infocards img {
  width: 100%;
  height: 100%;
}
.tourdetails .inficon .infocards .cont {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #2f5634;
  display: none;
}
.tourdetails .inficon .infocards .cont h6 {
  text-transform: capitalize;
  font-size: 18px;
}
@media (max-width: 576px) {
  .tourdetails .inficon .infocards .cont h6 {
    font-size: 16px;
  }
}
.tourdetails .inficon .infocards:hover img {
  display: none;
}
.tourdetails .inficon .infocards:hover .cont {
  display: flex;
}
.tourdetails .bannnerstwo {
  padding: 60px 120px;
}
@media (max-width: 1200px) {
  .tourdetails .bannnerstwo {
    padding: 60px 60px;
  }
}
@media (max-width: 992px) {
  .tourdetails .bannnerstwo {
    padding: 60px 30px;
  }
}
.tourdetails .bannnerstwo .gallery {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 10w);
  grid-template-areas: "item1 item2" "item1 item4" "item3 item4" "item3 item5" "item6 item6";
}
@media (max-width: 576px) {
  .tourdetails .bannnerstwo .gallery {
    gap: 5px;
  }
}
.tourdetails .bannnerstwo .gallery .item1 {
  grid-area: item1;
}
.tourdetails .bannnerstwo .gallery .item2 {
  grid-area: item2;
}
.tourdetails .bannnerstwo .gallery .item3 {
  grid-area: item3;
}
.tourdetails .bannnerstwo .gallery .item4 {
  grid-area: item4;
}
.tourdetails .bannnerstwo .gallery .item5 {
  grid-area: item5;
}
.tourdetails .bannnerstwo .gallery .item6 {
  height: 150px;
  grid-area: item6;
}
@media (max-width: 576px) {
  .tourdetails .bannnerstwo .gallery .item2 img,
  .tourdetails .bannnerstwo .gallery .item5 img {
    height: 100px;
  }
}
@media (max-width: 576px) {
  .tourdetails .bannnerstwo .gallery .item4 img,
  .tourdetails .bannnerstwo .gallery .item1 img,
  .tourdetails .bannnerstwo .gallery .item3 img {
    height: 200px;
  }
}
.tourdetails .bannnerstwo .gallery img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.tourdetails .bannnerstwo .textsection h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 12px;
}
.tourdetails .bannnerstwo .textsection p {
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tourdetails .bannnerstwo .textsection ul,
.tourdetails .bannnerstwo .textsection ol {
  padding-top: 20px;
  list-style: disc;
  padding-left: 1rem;
}
.tourdetails .bannnerstwo .textsection ul li,
.tourdetails .bannnerstwo .textsection ol li {
  padding-bottom: 1rem;
}/*# sourceMappingURL=style.css.map */