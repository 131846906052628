.facts {
  background: url("../../assets/images/facts/bg.png") center/cover no-repeat black;
  mix-blend-mode: multiply;
  padding: 60px 140px;
  display: flex;
  justify-content: center;
}
@media (max-width: 1200px) {
  .facts {
    padding: 60px 60px;
  }
}
.facts .heading {
  padding: 0px 15px;
}
.facts .heading h2,
.facts .heading p {
  text-align: left;
}
@media (max-width: 1200px) {
  .facts .heading h2 {
    font-size: 30px;
  }
}
@media (max-width: 576px) {
  .facts .heading h2 {
    text-align: left;
  }
}
@media (max-width: 1025px) {
  .facts .heading {
    margin-bottom: 22px;
  }
}
.facts .basisss {
  border: 2px solid white;
}
.facts .facttitle {
  top: 0;
  bottom: 0;
  left: 15px;
  z-index: 9;
  transform: rotate(270deg);
}
@media (max-width: 500px) {
  .facts .facttitle {
    left: 0;
  }
}
.facts .facttitle h4 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background-color: #7df08e;
  padding: 15px 30px;
}
@media (max-width: 1025px) {
  .facts .sides {
    flex-wrap: wrap;
    justify-content: center;
  }
}
.facts .sides .side1 {
  margin-top: -40px;
  margin-right: 20px;
  z-index: 2;
  width: 530px;
  height: 350px;
}
.facts .sides .side1 .swiper-pagination-bullet {
  width: 40px;
  height: 4px;
  border-radius: 0;
}
.facts .sides .side1 .swiper-pagination-bullet-active {
  background: #7df08e;
}
@media (max-width: 1250px) {
  .facts .sides .side1 {
    margin-top: 0;
    width: 450px;
    height: 310px;
  }
}
@media (max-width: 1100px) {
  .facts .sides .side1 {
    height: 340px;
    margin-right: 0px;
  }
}
@media (max-width: 1024px) {
  .facts .sides .side1 {
    height: 340px;
    width: 600px;
  }
}
@media (max-width: 768px) {
  .facts .sides .side1 {
    width: 600px;
  }
}
@media (max-width: 670px) {
  .facts .sides .side1 {
    width: 400px;
  }
}
@media (max-width: 440px) {
  .facts .sides .side1 {
    width: 350px;
    height: 100%;
  }
}
@media (max-width: 380px) {
  .facts .sides .side1 {
    width: 300px;
    height: 100%;
  }
}
.facts .sides .side1 .slider1 img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.facts .sides .side1 .slider1 .slick-dots {
  bottom: 20px;
}
.facts .sides .side1 .slider1 .slick-dots li {
  width: 45px;
  height: 3px;
}
.facts .sides .side1 .slider1 .slick-dots .slick-active {
  background-color: #7df08e;
}
.facts .sides .side1 .slider1 .slick-dots li button {
  background-color: white;
  height: 3px;
  width: 40px;
  padding: 0px;
}
.facts .sides .side1 .slider1 .slick-dots li button:before {
  content: "";
  height: 2px !important;
  width: 40px !important;
}
.facts .sides .side1 .slider1 .slick-dots li.slick-active button {
  height: 2px !important;
}
.facts .sides .side1 .slider1 .slick-dots li.slick-active button:before {
  background-color: #7df08e;
  height: 3px !important;
  width: 80px;
}
.facts .sides .slick-next {
  transform: rotate(90deg);
}
.facts .sides .slick-prev {
  transform: rotate(90deg);
}
.facts .sides .side2 {
  border: 2px solid white;
  width: 600px;
  height: 310px;
  overflow-y: hidden;
}
@media (max-width: 1250px) {
  .facts .sides .side2 {
    width: 600px;
    height: 310px;
  }
}
@media (max-width: 1100px) {
  .facts .sides .side2 {
    width: 550px;
    height: 340px;
  }
}
@media (max-width: 1025px) {
  .facts .sides .side2 {
    margin-top: 30px;
    width: 600px;
    height: 100%;
  }
}
@media (max-width: 670px) {
  .facts .sides .side2 {
    width: 400px;
    height: 100%;
  }
}
@media (max-width: 400px) {
  .facts .sides .side2 {
    width: 340px;
  }
}
@media (max-width: 365px) {
  .facts .sides .side2 {
    width: 300px;
  }
}
.facts .sides .side2 .slider2 .swiper-button-prev {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  color: black;
  font-weight: 900;
}
.facts .sides .side2 .slider2 .swiper-button-prev:after {
  font-size: 16px;
}
.facts .sides .side2 .slider2 .swiper-button-next {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #fff;
  color: black;
  font-weight: 900;
}
.facts .sides .side2 .slider2 .swiper-button-next:after {
  font-size: 16px;
}
.facts .sides .side2 .slider2 .items {
  padding: 20px 40px;
}
.facts .sides .side2 .slider2 h2 {
  color: #fff;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
}
.facts .sides .side2 .slider2 p {
  color: white;
}/*# sourceMappingURL=fact.css.map */