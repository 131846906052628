.enquiry .Contactusform {
  position: fixed;
  height: 100vh;
  left: 0;
  right: 0;
  transition: 1s ease-in;
  top: 0;
  z-index: 99;
  color: white;
  width: 100%;
  overflow-y: scroll;
  background-color: #0e1810;
}
.enquiry .Contactusform::-webkit-scrollbar {
  display: none;
}
.enquiry .Contactusform .head {
  background-color: #2f5634;
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.enquiry .Contactusform .formbody {
  width: 100%;
  padding: 30px 120px;
}
@media (max-width: 768px) {
  .enquiry .Contactusform .formbody {
    padding: 60px 30px;
  }
}
.enquiry .Contactusform .formbody .headstext {
  text-align: center;
  margin-top: 60px;
  margin-bottom: 70px;
}
.enquiry .Contactusform .formbody .headstext h5 {
  color: #fff;
  text-align: center;
  letter-spacing: 3px;
  font-size: 40px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.enquiry .Contactusform .formbody .headstext p {
  font-size: 18px;
  padding-top: 16px;
}
.enquiry .Contactusform .formbody select {
  background-color: #0e1810;
}
.enquiry .Contactusform .formbody .form-field {
  background-color: #0a120b !important;
  padding: 0px 10px;
  margin-bottom: 4px;
  border-bottom: 1px solid #fff;
}
.enquiry .Contactusform .formbody .form-field input {
  color: white;
  padding: 14px 20px;
  background-color: #0a120b;
  width: 100%;
}
.enquiry .Contactusform .formbody .form-fieldphone {
  border-bottom: 1px solid white;
}
.enquiry .Contactusform .formbody .form-fieldphone input {
  padding: 14px 20px;
  background-color: #0e1810;
}
.enquiry .Contactusform .formbody textarea {
  width: 100%;
  background-color: #0a120b;
  padding: 14px 20px;
  color: #ffffff;
  height: 100px;
}
.enquiry .Contactusform .formbody .button1 {
  margin-top: 100px;
}
.enquiry .Contactusform .otpbody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.enquiry .Contactusform .otpbody h5 {
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.enquiry .Contactusform .otpbody p {
  padding: 20px;
  color: white;
  font-size: 20px;
}
.enquiry .Contactusform .otpbody input {
  margin: 50px 10px;
  border-bottom: 2px solid white;
  height: 50px;
  width: 50px !important;
  background-color: black;
  color: white;
}
.enquiry .Contactusform .thanks {
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  flex-direction: column;
}
.enquiry .Contactusform .thanks img {
  height: 200px;
  width: 200px;
  margin-left: 30px;
}
.enquiry .Contactusform .thanks h5 {
  font-size: 40px;
  color: white;
  text-transform: uppercase;
}
.enquiry .Contactusform .thanks p {
  font-size: 20px;
  color: white;
}
.enquiry .tsfbefore {
  transform: translateY(4000%);
  transition: 500ms ease-in;
}
.enquiry .tsfafter {
  transition: 500ms ease-in;
  transform: translateY(0%);
}/*# sourceMappingURL=style.css.map */