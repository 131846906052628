.mainsidebar {
  width: 100%;
  height: 100vh;
  background-color: #00000000;
  z-index: 99;
  position: fixed;
  .sidebar {
    z-index: 999;
    margin-left: auto;
    width: 700px;
    height: 100vh;
    top: 0;
    right: 0;
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 35px 0px;
    @media (max-width: 768px) {
      width: 450px;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
    .head {
      padding: 0px 120px;
      @media (max-width: 768px) {
        padding: 0px 60px;
      }
      h5 {
        color: var(--Black, #000);
        font-family: Ubuntu;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.4px;
        text-transform: uppercase;
      }
    }
    .navs {
      ul {
        list-style: none;
        cursor: pointer;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0px 120px;
          height: 70px;
          color: #000;
          font-family: var(--ubuntu);
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-transform: uppercase;
          position: relative;

          align-items: center;
          z-index: 99;
          &:hover {
            color: white;
          }
          @media (max-width: 768px) {
            padding: 0px 60px;
            font-size: 20px;
            height: 50px;
          }
        }

        li::before {
          content: "";
          position: absolute;
          inset: 0;
          width: 0%;
          height: 100%;
          background-color: #000;
          z-index: -1;
          transition: width 0.3s ease;
        }

        li:hover::before {
          width: 100%;
        }
      }
    }
    .social-icon {
      padding: 0px 120px;
      @media (max-width: 576px) {
        padding: 0px 60px;
        margin-bottom: 3rem;
      }
      ul {
        li {
          margin-right: 40px;
          padding: 5px;
          &:hover {
            border-radius: 50%;
            box-shadow: 0px 0px 20px rgb(219, 217, 217);
            padding: 5px;
          }
          @media (max-width: 768px) {
            img {
              max-width: 50px;
              height: 25px;
            }
          }
          @media (max-width: 576px) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@keyframes nav {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
