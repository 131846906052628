.faqs {
  padding-top: 120px;
  background-color: black;
  color: white;
  padding: 0px 60px 60px 60px;
}
@media (max-width: 768px) {
  .faqs {
    padding: 0px 0px 0px 0px;
  }
}/*# sourceMappingURL=faq.css.map */