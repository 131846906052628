header {
  padding: 0px 140px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  background: rgba(0, 0, 0, 0.33);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
  @media (max-width: 1200px) {
    padding: 0px 60px;
  }
  @media (max-width: 992px) {
    padding: 0px 50px;
  }
  @media (max-width: 768px) {
    padding: 0px 30px;
  }
  @media (max-width: 576px) {
    padding: 0px 30px;
  }
  @media (max-width: 400px) {
    padding: 0px 15px;
  }
  .logomain {
    @media (max-width: 992px) {
      width: 60px;
    }
    @media (max-width: 470px) {
      width: 50px;
    }
    @media (max-width: 435px) {
      width: 50px;
    }
  }
  .iat {
    width: 45.87px;
    height: 28.06px;

    @media (max-width: 470px) {
      width: 45px;
    }
    @media (max-width: 435px) {
      width: 35px;
    }
  }
  .nbaa {
    width: 93.07px;
    height: 24.55px;
    @media (max-width: 470px) {
      width: 75px;
    }
    @media (max-width: 435px) {
      width: 60px;
      height: 20.55px;
    }
  }
  .btn1 {
    border-radius: 6px;
    width: fit-content;
    color: white;
    border: 2px solid white;
    font-size: 20px;
    padding: 5px 15px;
    background: transparent;
    @media (max-width: 992px) {
      padding: 3px 10px;
      font-size: 16px;
    }
    @media (max-width: 768px) {
      font-size: 16px;
    }
    @media (max-width: 576px) {
      font-size: 14px;
      padding: 3px 7px;
    }
    &:hover {
      background: linear-gradient(180deg, #b97208 0%, #ffd800 100%);
    }
  }
  h2 {
    color: #fff;
    font-family: var(--Ubuntu);
    font-size: 25.385px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 2.538px;
    text-transform: uppercase;
    @media (max-width: 1250px) {
      font-size: 22px;
      letter-spacing: 0.538px;
    }
    @media (max-width: 992px) {
      font-size: 18px;
      letter-spacing: 0.538px;
    }
    @media (max-width: 768px) {
      font-size: 18px;
      letter-spacing: 0.538px;
      font-weight: 500;
    }
    @media (max-width: 576px) {
      font-size: 14px;
    }
  }
}
